import { Directive, Injector, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractModel, AbstractService } from '@radar-workspace/ui';
import {
  CrudService,
  FeatureConfig,
  ModuleConfig,
} from '@radar-workspace/ui-api';
import { SolutionConfiguration } from '@radar-workspace/utils';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';
import { FormGroup } from '@angular/forms';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractFormComponent<T> implements OnInit {
  sc: SolutionConfiguration;
  formGroup: FormGroup;
  formModelData: AbstractModel;

  breadcrumbService: BreadcrumbService;
  router: Router;
  route: ActivatedRoute;
  formMode = 'create';

  constructor(
    protected service: CrudService<T>,
    protected moduleConfig: Type<ModuleConfig>,
    protected featureConfig: Type<FeatureConfig>,
    protected injector: Injector
  ) {
    this.sc = new SolutionConfiguration(moduleConfig, featureConfig);

    this.breadcrumbService = this.injector.get(BreadcrumbService);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
  }

  ngOnInit(): void {
    this.setBreadcrumbs();
    this.initializeForm();
    this.populateForm();
  }

  setBreadcrumbs() {
    this.breadcrumbService.setItems([
      {
        label: this.sc.moduleConfig.moduleName,
        routerLink: ['/' + this.sc.moduleConfig.moduleUrlPrefix],
      },
      {
        label: this.sc.featureConfig.featureName,
        routerLink: [
          '/' +
            this.sc.moduleConfig.moduleUrlPrefix +
            '/' +
            this.sc.featureConfig.featureUrlPrefix,
        ],
      },
    ]);
  }

  populateForm() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.formMode = 'edit';
      this.service.readOne(id).subscribe((record) => {
        this.formModelData = record;
        this.formGroup.patchValue(record);
      });
    }
  }

  initializeForm() {
    throw new Error('Method should be implemented in subclass.');
  }

  onFormSubmit() {
    if (this.formMode == 'create') {
      this.service.create(this.formGroup.value as T).subscribe((data) => {
        this.router.navigate(
          [
            '/' +
              this.sc.moduleConfig.moduleUrlPrefix +
              '/' +
              this.sc.featureConfig.featureUrlPrefix,
          ],
          {
            relativeTo: this.route,
          }
        );
      });
    } else if (this.formMode == 'edit') {
      this.service.update(this.formGroup.value as T).subscribe((data) => {
        this.router.navigate(
          [
            '/' +
              this.sc.moduleConfig.moduleUrlPrefix +
              '/' +
              this.sc.featureConfig.featureUrlPrefix,
          ],
          {
            relativeTo: this.route,
          }
        );
      });
    }
  }

  onFormDiscard(e: Event) {
    this.router.navigate(
      [
        '/' +
          this.sc.moduleConfig.moduleUrlPrefix +
          '/' +
          this.sc.featureConfig.featureUrlPrefix,
      ],
      {
        relativeTo: this.route,
      }
    );
  }
}
