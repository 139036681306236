import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@radar-workspace/ui';
import { Perspective } from './perspective.model';

@Injectable({
  providedIn: 'root',
})
export class PerspectivesService extends AbstractService<Perspective> {
  constructor(http: HttpClient) {
    super(http, 'assets/demo/data/perspectives.json');
  }
}
