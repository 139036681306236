import { Component, Injector, OnInit } from '@angular/core';
import { AbstractGridComponent } from '@radar-workspace/ui';
import { resourceUsage } from 'process';
import { forkJoin } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { Perspective } from '../../../administration/perspectives/perspective.model';
import { PerspectivesService } from '../../../administration/perspectives/perspectives.service';
import { Job } from '../../jobs/job.model';
import { JobsService } from '../../jobs/jobs.service';
import { ProcessingModuleConfig } from '../../processing.config';
import { HistoryFeatureConfig } from '../history.feature';
import { JobHistory } from '../history.model';
import { HistoryService } from '../history.service';

@Component({
  selector: 'radar-workspace-jobs-grid',
  templateUrl: './history-grid.component.html',
  styleUrls: ['./history-grid.component.scss'],
})
export class HistoryGridComponent
  extends AbstractGridComponent<JobHistory>
  implements OnInit {
  statusToSeverityMap = {
    PENDING: 'info',
    FINISHED: 'success',
    RUNNING: 'warning',
    FAIL: 'danger',
  };

  constructor(
    historyService: HistoryService,
    injector: Injector,
    private perspectiveService: PerspectivesService,
    private jobService: JobsService
  ) {
    super(
      historyService,
      ProcessingModuleConfig,
      HistoryFeatureConfig,
      injector
    );
  }

  initializeGrid() {
    this.service
      .readAll()
      .pipe(
        mergeMap((records: any[]) => {
          return forkJoin(
            records.map((record) => {
              return this.jobService.readOne(record.jobId).pipe(
                switchMap((job) =>
                  this.perspectiveService.readOne(job.perspective)
                ),
                map((per: any) => {
                  record.jobName = per.name;
                  return record;
                })
              );
            })
          );
        })
      )
      .subscribe((data) => {
        this.gridRecords = data;
      });
  }
}
