import { AbstractModel } from '@radar-workspace/ui';
import { SelectItem } from 'primeng/api';

export class DatasourceField extends AbstractModel {
  id: string;
  fieldName: string;
  tableName: string;
  label: string;
}

export class DatasourceTable extends AbstractModel {
  tableFullName: string;
  tableName: string;
  fieldList: DatasourceField[];
}

export class Datasource extends AbstractModel {
  name: string;
  description: string;
  type: string;
  connectionString?: string;
  tableList: DatasourceTable[];
}

export enum DatasourceType {
  Oracle = 'Oracle',
  Postgres = 'Postgres',
  MySQL = 'MySQL',
  DB2 = 'DB2',
  MongoDB = 'MongoDB',
  Elasticsearch = 'Elasticsearch',
  API = 'API',
}
