<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card">
      <h5>{{ sc.featureConfig.featureName }}</h5>
      <p>{{ sc.featureConfig.featureDescription }}</p>

      <p-table
        #dt
        [value]="gridRecords"
        [(selection)]="gridRecordsSelected"
        dataKey="id"
        styleClass="p-datatable-customers"
        [rowHover]="true"
        [rows]="10"
        [paginator]="true"
        [filterDelay]="0"
        [globalFilterFields]="[
          'name',
          'country.name',
          'representative.name',
          'status'
        ]"
      >
        <ng-template pTemplate="caption">
          <div
            class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header"
          >
            <p-button
              pRipple
              type="button"
              label="Add new"
              (onClick)="onGridMenuCreate($event)"
              class="p-button-raised p-mr-2 p-mb-2"
            ></p-button>
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Global Search"
              />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="name">
              <div class="p-d-flex p-jc-between p-ai-center">
                Name
                <p-sortIcon field="name"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="name"
                  display="menu"
                  class="p-ml-auto"
                ></p-columnFilter>
              </div>
            </th>
            <th pSortableColumn="country.name">
              <div class="p-d-flex p-jc-between p-ai-center">
                Description
                <p-sortIcon field="country.name"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="country.name"
                  display="menu"
                  class="p-ml-auto"
                ></p-columnFilter>
              </div>
            </th>
            <th style="width: 8rem"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record>
          <tr class="p-selectable-row">
            <td>
              <p-tableCheckbox [value]="record"></p-tableCheckbox>
            </td>
            <td>
              {{ record.name }}
            </td>
            <td>
              {{ record.description }}
            </td>
            <td style="text-align: center">
              <button
                (click)="onGridShowRowMenu($event, record)"
                pButton
                type="button"
                class="p-button-rounded p-button-secondary p-button-text"
                icon="pi pi-ellipsis-v"
              ></button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">No perspectives found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-menu #menu [popup]="true" [model]="gridMenuItems"></p-menu>
