import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from '@radar-workspace/ui-api';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AbstractModel } from '../model/abstract.model';
import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil } from '@radar-workspace/utils';
import { SelectItem } from 'primeng/api';

@Injectable()
export class AbstractService<T extends AbstractModel>
  implements CrudService<T> {
  protected _cache: T[] = [];

  public updateLocalStorage$ = new Subject();

  constructor(private http: HttpClient, protected jsonFilePath: string) {
    this.updateLocalStorage$.subscribe((item) => {
      localStorage.setItem(this.jsonFilePath, JSON.stringify(this._cache));
    });
  }

  private _create(model: T): Observable<T> {
    model.id = uuidv4();
    this._cache.push(model);

    this.updateLocalStorage$.next();

    return of(model);
  }

  create(model: T): Observable<T> {
    if (this._cache.length <= 0) {
      this.loadCacheFromFile().subscribe(() => {
        return this._create(model);
      });
    } else {
      return this._create(model);
    }
  }

  private _readOne(id: string): Observable<T> {
    const result = this._cache.find((model) => model.id == id);

    if (result) {
      return of(result);
    } else {
      console.log('Cannot find ID: ', id);
    }
  }

  readOne(id: string): Observable<T> {
    if (this._cache.length <= 0) {
      this.loadCacheFromFile().subscribe(() => {
        return this._readOne(id);
      });
    } else {
      return this._readOne(id);
    }
  }

  readAll(): Observable<T[]> {
    if (this._cache.length > 0) {
      return of(this._cache);
    }

    return this.loadCacheFromFile();
  }

  search(searchCriteria: unknown): Observable<T[]> {
    if (this._cache.length > 0) {
      return of(this._cache);
    }

    return this.loadCacheFromFile();
  }

  private _update(model: T): Observable<T> {
    if (model) {
      // ArrayUtil.removeFromArray(this._cache, model, 'id');
      const index = this._cache.findIndex((item) => item.id == model.id);
      console.log('model to upadet', model);
      const updatedModel = { ...this._cache[index], ...model };
      this._cache[index] = updatedModel;
      this.updateLocalStorage$.next();

      return of(updatedModel);
    }

    return of(model);
  }

  update(model: T): Observable<T> {
    if (this._cache.length <= 0) {
      this.loadCacheFromFile().subscribe(() => {
        return this._update(model);
      });
    } else {
      return this._update(model);
    }
  }

  private _delete(model: T): Observable<T> {
    if (model) {
      ArrayUtil.removeFromArray(this._cache, model, 'id');

      this.updateLocalStorage$.next();
    }

    return of(model);
  }

  delete(model: T): Observable<T> {
    if (this._cache.length <= 0) {
      this.loadCacheFromFile().subscribe(() => {
        return this._delete(model);
      });
    } else {
      return this._delete(model);
    }
  }

  private _deleteById(id: string): Observable<T> {
    const model = this._cache.find((model) => model.id === id);

    if (model) {
      ArrayUtil.removeFromArray(this._cache, model, 'id');

      this.updateLocalStorage$.next();
    }

    return of(model);
  }

  deleteById(id: string): Observable<T> {
    if (this._cache.length <= 0) {
      this.loadCacheFromFile().subscribe(() => {
        return this._deleteById(id);
      });
    } else {
      return this._deleteById(id);
    }
  }

  loadCacheFromFile() {
    if (localStorage.getItem(this.jsonFilePath)) {
      this._cache = JSON.parse(localStorage.getItem(this.jsonFilePath)) as T[];
      return of(this._cache);
    } else {
      return this.http.get<any>(this.jsonFilePath).pipe(
        map((res) => {
          res.data as T[];
          return res.data;
        }),
        tap((data) => {
          this._cache = [...data];
        })
      );
    }
  }

  getSelectItemList(labelName: string): SelectItem[] {
    if (this._cache)
      return this._cache.map((element) => {
        return {
          label: element[labelName],
          value: element.id,
        };
      });
  }
}
