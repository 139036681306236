import { Type } from '@angular/core';
import { FeatureConfig, ModuleConfig } from '@radar-workspace/ui-api';

export class SolutionConfiguration {
  private _moduleConfig: ModuleConfig;
  private _featureConfig: FeatureConfig;
  constructor(
    moduleConfig: Type<ModuleConfig>,
    featureConfig: Type<FeatureConfig>
  ) {
    this._moduleConfig = new moduleConfig();
    this._featureConfig = new featureConfig();
  }

  get moduleConfig(): ModuleConfig {
    return this._moduleConfig;
  }

  get featureConfig(): FeatureConfig {
    return this._featureConfig;
  }
}
