import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@radar-workspace/ui';
import { Job } from './job.model';

@Injectable({
  providedIn: 'root',
})
export class JobsService extends AbstractService<Job> {
  constructor(http: HttpClient) {
    super(http, 'assets/demo/data/jobs.json');
  }
}
