import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AbstractFormComponent } from '@radar-workspace/ui';
import { SelectItem } from 'primeng/api';
import { AdministrationModuleConfig } from '../../administration.config';
import { DatasourcesService } from '../../datasources/datasources.service';
import { SectionsService } from '../../sections/sections.service';
import { PerspectiveFeatureConfig } from '../perspective.feature';
import { Perspective, PerspectiveDatasourceMap } from '../perspective.model';
import { PerspectivesService } from '../perspectives.service';

@Component({
  selector: 'radar-workspace-mappings-form',
  templateUrl: './mappings-form.component.html',
  styleUrls: ['./mappings-form.component.scss'],
})
export class MappingsFormComponent
  extends AbstractFormComponent<Perspective>
  implements OnInit {
  datasources: SelectItem[] = [];
  datasourceIdNameMap = {};
  datasourceFields = {};
  perspectiveId: string;
  perspective: Perspective;
  predefinedDatasource: string;

  activeTabIndex = 0;
  datasourceTables = {};
  datasourceTableFields = {};

  constructor(
    private perspectivesService: PerspectivesService,
    injector: Injector,
    private formBuilder: FormBuilder,
    private datasourcesService: DatasourcesService,
    private sectionsService: SectionsService
  ) {
    super(
      perspectivesService,
      AdministrationModuleConfig,
      PerspectiveFeatureConfig,
      injector
    );
  }

  ngOnInit(): void {
    this.setBreadcrumbs();

    this.datasources = this.datasourcesService.getSelectItemList('name');
    this.datasources.forEach((datasource) => {
      this.datasourceIdNameMap[datasource.value] = datasource.label;
      this.datasourceTables[datasource.value] = [];
      this.datasourceTableFields[datasource.value] = [];
    });

    this.perspectiveId = this.route.snapshot.paramMap.get('id');
    this.sectionsService.setPerspectiveById(this.perspectiveId);

    this.sectionsService.perspective$.subscribe((data) => {
      this.perspective = data;

      this.initializeForm();
      this.populateForm();
    });
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      mappings: this.formBuilder.array([]),
    });
  }

  populateForm() {
    this.formMode = 'edit';
    this.formModelData = this.perspective;
    const data = {
      mappings: [],
    };
    const groupedByDatasourceId = {};

    this.perspective.mappings.forEach((mapping) => {
      if (!groupedByDatasourceId[mapping.datasourceId]) {
        groupedByDatasourceId[mapping.datasourceId] = [];
      }

      groupedByDatasourceId[mapping.datasourceId].push(mapping);
    });

    Object.entries(groupedByDatasourceId).forEach(([key, fields]) => {
      const mappings = {
        datasourceId: key,
      };

      this.reduceMainDatasourceList(key);
      this.createNewMapTab(key);

      (fields as []).forEach((field: PerspectiveDatasourceMap) => {
        mappings[field.perspectiveField] = field;
        this.populateTableFieldsForFieldKEy(
          key,
          field.datasourceTable,
          field.perspectiveField
        );
      });

      data.mappings.push(mappings);
    });

    this.formGroup.patchValue(data);
  }

  onShowFields(event: Event) {
    this.router.navigate(
      ['/administration/perspectives/define-fields/' + this.perspectiveId],
      {
        relativeTo: this.route,
      }
    );
  }

  onDatasourceSelected(datasourceId: string, value: any, field: string) {
    this.populateTableFieldsForFieldKEy(datasourceId, value, field);
  }

  populateTableFieldsForFieldKEy(
    datasourceId: string,
    value: any,
    field: string
  ) {
    this.datasourcesService.readOne(datasourceId).subscribe((datasource) => {
      datasource.tableList.forEach((table) => {
        if (table.tableName == value)
          this.datasourceTableFields[datasource.id][
            field
          ] = table.fieldList.map((field) => {
            return {
              label: field.label,
              value: field.id,
            };
          });
      });
    });
  }

  onFormSubmit() {
    const mappings = [];
    this.formGroup.value.mappings.forEach((mapedFields) => {
      Object.entries(mapedFields as Object).forEach(([key, value]) => {
        if (key === 'datasourceId') return;
        mappings.push({
          ...value,
          ...{ datasourceId: mapedFields.datasourceId },
        });
      });
    });

    this.perspective.mappings = mappings;
    this.perspectivesService.update(this.perspective).subscribe((data) => {
      this.router.navigate(
        [
          '/' +
            this.sc.moduleConfig.moduleUrlPrefix +
            '/' +
            this.sc.featureConfig.featureUrlPrefix,
        ],
        {
          relativeTo: this.route,
        }
      );
    });
  }

  onAddNewDataSeource() {
    if (this.predefinedDatasource) {
      this.reduceMainDatasourceList(this.predefinedDatasource);
      this.createNewMapTab(this.predefinedDatasource);
      this.predefinedDatasource = null;
    }
  }

  reduceMainDatasourceList(datasourceId: string) {
    this.datasources = this.datasources.filter(
      (selectItem: SelectItem) => selectItem.value != datasourceId
    );
  }

  createNewMapTab(datasourceId: string) {
    const mappings = {
      datasourceId: this.formBuilder.control(datasourceId),
    };

    this.datasourceTableFields[datasourceId] = {};

    this.perspective.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.key) {
          this.datasourceFields[field.key] = [];
          this.datasourceTableFields[datasourceId][field.key] = [];

          mappings[field.key] = this.formBuilder.group({
            perspectiveField: field.key,
            datasourceId: '',
            datasourceTable: '',
            datasourceField: '',
          });
        }
      });
    });

    const formArray = this.formGroup.controls.mappings as FormArray;
    formArray.push(this.formBuilder.group(mappings));

    this.datasourcesService.readOne(datasourceId).subscribe((datasource) => {
      this.datasourceTables[datasourceId] = [];

      datasource.tableList.forEach((table) => {
        this.datasourceTables[datasourceId].push({
          label: table.tableName,
          value: table.tableName,
        });
      });
    });
  }

  onMappingTabClose(event: any) {
    const formArray = this.formGroup.controls.mappings as FormArray;
    const datasourceId = formArray.controls[event.index].value.datasourceId;

    this.datasourcesService.readOne(datasourceId).subscribe((datasource) => {
      this.datasources.push({
        label: datasource.name,
        value: datasourceId,
      });
    });

    formArray.removeAt(event.index);
  }
}
