import { Component, Injector, OnInit } from '@angular/core';
import { Datasource } from '../datasource.model';
import { DatasourcesService } from '../datasources.service';
import { AbstractGridComponent } from '@radar-workspace/ui';
import { AdministrationModuleConfig } from '../../administration.config';
import { DatasourceFeatureConfig } from '../datasource.feature';

@Component({
  selector: 'radar-workspace-datasources-grid',
  templateUrl: './datasources-grid.component.html',
  styleUrls: ['./datasources-grid.component.scss'],
})
export class DatasourcesGridComponent
  extends AbstractGridComponent<Datasource>
  implements OnInit {
  constructor(datasourceService: DatasourcesService, injector: Injector) {
    super(
      datasourceService,
      AdministrationModuleConfig,
      DatasourceFeatureConfig,
      injector
    );
  }
}
