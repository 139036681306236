import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractGridComponent } from '@radar-workspace/ui';
import { AdministrationModuleConfig } from '../../administration.config';
import { PerspectivesService } from '../../perspectives/perspectives.service';
import { SectionFeatureConfig } from '../section.feature';
import { Field, Section } from '../section.model';
import { SectionsService } from '../sections.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SectionFormComponent } from '../section-form/section-form.component';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { FieldFormComponent } from '../field-form/field-form.component';
import { Perspective } from '../../perspectives/perspective.model';

@Component({
  selector: 'radar-workspace-search-grid',
  templateUrl: './section-grid.component.html',
  styleUrls: ['./section-grid.component.scss'],
  providers: [DialogService],
})
export class SectionGridComponent
  extends AbstractGridComponent<Section>
  implements OnInit, OnDestroy {
  perspectiveId: string;
  perspective: Perspective;
  sectionDialogRef: DynamicDialogRef;
  fieldDialogRef: DynamicDialogRef;
  fieldMenuItems: MenuItem[];
  gridFieldRecordMenuSelected: Field;
  rowIndex: number;

  @ViewChild('fieldMenu') fieldRecordMenu: Menu;

  isRowsExpanded = false;
  expandedRows = {};

  constructor(
    private sectionsService: SectionsService,
    injector: Injector,
    private perspectiveService: PerspectivesService,
    private dialogService: DialogService
  ) {
    super(
      sectionsService,
      AdministrationModuleConfig,
      SectionFeatureConfig,
      injector
    );
  }

  ngOnInit(): void {
    this.setBreadcrumbs();
    this.initializeGridMenu();

    this.perspectiveId = this.route.snapshot.paramMap.get('id');
    this.sectionsService.setPerspectiveById(this.perspectiveId);

    this.sectionsService.perspective$.subscribe((data) => {
      this.perspective = data;
      this.gridRecords = data.sections;
    });
  }

  initializeGridMenu() {
    super.initializeGridMenu();

    this.gridMenuItems.unshift({
      label: 'Add new field',
      icon: 'pi pi-fw pi-key',
      command: () => this.onAddNewField(),
    });

    if (!this.fieldMenuItems) {
      this.fieldMenuItems = [
        {
          label: 'Edit field',
          icon: 'pi pi-fw pi-cog',
          command: () => this.onFieldMenuEdit(),
        },
        {
          label: 'Delete field',
          icon: 'pi pi-fw pi-trash',
          command: () => this.onFieldMenuDelete(),
        },
      ];
    }
  }

  onSectionCreate() {
    this.sectionDialogRef = this.dialogService.open(SectionFormComponent, {
      data: {
        id: null,
        perspectiveId: this.perspectiveId,
      },
      header: 'Create new section',
      width: '30%',
    });
  }

  onGridMenuEdit() {
    this.sectionDialogRef = this.dialogService.open(SectionFormComponent, {
      data: {
        id: this.gridRecordMenuSelected.id,
      },
      header: 'Edit section',
      width: '30%',
    });
  }

  onGridMenuDelete() {
    this.confirmationService.confirm({
      message:
        'Are you sure that you want to delete this ' +
        this.sc.featureConfig.featureName.toLocaleLowerCase() +
        '?',
      accept: () => {
        this.service
          .deleteById(this.gridRecordMenuSelected.id)
          .subscribe((d) => {
            console.log('deleted object', d);
            this.messageService.add({
              key: 'bc',
              severity: 'success',
              summary: 'Success',
              detail:
                'Deleted ' +
                this.sc.featureConfig.featureName.toLocaleLowerCase(),
            });
          });
      },
    });
  }

  onFieldShowRowMenu(event: Event, sectionRecord: Section, fieldRecord: Field) {
    this.gridRecordMenuSelected = sectionRecord;
    this.gridFieldRecordMenuSelected = fieldRecord;
    this.fieldRecordMenu.toggle(event);
  }

  onAddNewField() {
    this.fieldDialogRef = this.dialogService.open(FieldFormComponent, {
      data: {
        id: null,
        sectionId: this.gridRecordMenuSelected.id,
      },
      header: 'Create new field',
      width: '30%',
    });
  }

  onFieldMenuEdit() {
    this.fieldDialogRef = this.dialogService.open(FieldFormComponent, {
      data: {
        id: this.gridFieldRecordMenuSelected.id,
        sectionId: this.gridRecordMenuSelected.id,
      },
      header: 'Edit field',
      width: '40%',
    });

    this.fieldDialogRef.onClose.subscribe((field: Field) => {
      if (field) {
        console.log('field on close', field);
      }
    });
  }

  onFieldMenuDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this field?',
      accept: () => {
        this.sectionsService
          .deleteFieldById(
            this.gridRecordMenuSelected.id,
            this.gridFieldRecordMenuSelected.id
          )
          .subscribe((d) => {
            console.log('deleted object', d);
            this.messageService.add({
              key: 'bc',
              severity: 'success',
              summary: 'Success',
              detail: 'Deleted field',
            });
          });
      },
    });
  }

  onShowMappings(event: Event) {
    this.router.navigate(
      ['/administration/perspectives/define-mappings/' + this.perspectiveId],
      {
        relativeTo: this.route,
      }
    );
  }

  ngOnDestroy() {
    if (this.sectionDialogRef) {
      this.sectionDialogRef.close();
    }
    if (this.fieldDialogRef) {
      this.fieldDialogRef.close();
    }

    this.sectionsService.destroyPerpective();
  }

  onToggleExpandableRows() {
    this.isRowsExpanded = !this.isRowsExpanded;
    this.perspective.sections.forEach(
      (section) => (this.expandedRows[section.id] = this.isRowsExpanded)
    );
  }
}
