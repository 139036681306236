<form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <h5>
          Define mappings: <strong>{{ perspective.name }}</strong>
        </h5>
        <p>Map perspective fields to datasource fields</p>

        <p-toolbar>
          <div class="p-toolbar-group-left">
            <p-dropdown
              [filter]="true"
              [options]="datasources"
              placeholder="Select datasource"
              (onChange)="predefinedDatasource = $event.value"
            ></p-dropdown
            >&nbsp;&nbsp;
            <button
              pButton
              type="button"
              class="p-button-outlined p-button-rounded p-button-sm"
              icon="pi pi-plus"
              (click)="onAddNewDataSeource()"
            ></button>
          </div>
          <div class="p-toolbar-group-right">
            <button
              pButton
              type="button"
              label="Define fields"
              class="p-button-secondary"
              icon="pi pi-sitemap"
              (click)="onShowFields($event)"
            ></button>
          </div>
        </p-toolbar>
      </div>

      <div
        class="card"
        *ngIf="formGroup['controls']['mappings']['controls'].length > 0"
      >
        <p-tabView
          [(activeIndex)]="activeTabIndex"
          (onClose)="onMappingTabClose($event)"
          formArrayName="mappings"
        >
          <ng-container
            *ngFor="
              let item of formGroup['controls']['mappings']['controls'];
              let i = index;
              let last = last;
              let first = first
            "
          >
            <p-tabPanel
              [selected]="first"
              [header]="datasourceIdNameMap[item.value.datasourceId]"
              [closable]="!first"
              [formGroupName]="i"
            >
              <ng-container *ngFor="let section of perspective.sections">
                <h5>{{ section.name }}</h5>

                <ng-container *ngFor="let field of section.fields">
                  <div
                    class="p-fluid p-formgrid p-grid"
                    formGroupName="{{ field.key }}"
                  >
                    <div class="p-field p-col-12 p-md-3">
                      <input
                        id="name"
                        type="text"
                        pInputText
                        readonly
                        value="{{ field.name }}"
                      />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                      <p-dropdown
                        [filter]="true"
                        formControlName="datasourceTable"
                        [options]="datasourceTables[item.value.datasourceId]"
                        placeholder="Select table"
                        (onChange)="
                          onDatasourceSelected(
                            item.value.datasourceId,
                            $event.value,
                            field.key
                          )
                        "
                      ></p-dropdown>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                      <p-dropdown
                        [filter]="true"
                        [options]="
                          datasourceTableFields[item.value.datasourceId][
                            field.key
                          ]
                        "
                        placeholder="Select table field"
                        formControlName="datasourceField"
                      ></p-dropdown>
                    </div>
                  </div>
                </ng-container>

                <p-divider></p-divider>
              </ng-container>
            </p-tabPanel>
          </ng-container>
        </p-tabView>
      </div>
    </div>
  </div>

  <div class="p-grid p-jc-between">
    <div class="p-col-2">
      <button
        pButton
        pRipple
        type="submit"
        [disabled]="!formGroup.valid"
        label="Save"
      ></button>
    </div>
    <div class="p-col-1">
      <p-button
        pRipple
        type="button"
        label="Discard"
        (onClick)="onFormDiscard($event)"
        styleClass="p-button-secondary"
      ></p-button>
    </div>
  </div>
</form>
<!-- <pre>
  {{ formGroup.value | json }}
</pre> -->
