import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@radar-workspace/ui';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PerspectivesService } from '../../administration/perspectives/perspectives.service';
import { SearchResult, SearchShema } from './search.model';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends AbstractService<SearchResult> {
  constructor(
    http: HttpClient,
    private perspectiveService: PerspectivesService
  ) {
    super(http, 'assets/demo/data/search-results.json');
  }

  doSearch(searchShema: SearchShema): Observable<SearchResult> {
    this.jsonFilePath =
      'assets/demo/data/search-results-' + searchShema.perspectiveId + '.json';

    const fieldKeyFieldMap = {};

    this.perspectiveService
      .readOne(searchShema.perspectiveId)
      .subscribe((perspective) => {
        perspective.sections.forEach((section) => {
          section.fields.forEach((field) => {
            fieldKeyFieldMap[field.key] = field;
          });
        });
      });

    return this.loadCacheFromFile().pipe(
      map((data: Object[]) => {
        const filterOptions = {};
        let results = data;
        if (Object.keys(searchShema.search).length > 0) {
          results = data.filter((record) => {
            let found = true;
            for (const [sKey, sValue] of Object.entries(searchShema.search)) {
              if (!found) break;

              if (typeof sValue === 'object') {
                const searchValue = sValue as any;
                if (searchValue.range === true) {
                  if (fieldKeyFieldMap[sKey].type.toLowerCase() == 'date') {
                    found =
                      new Date(record[sKey]) >= new Date(searchValue.from) &&
                      new Date(record[sKey]) <= new Date(searchValue.to);
                  } else {
                    found =
                      record[sKey] >= searchValue.from &&
                      record[sKey] <= searchValue.to;
                  }
                } else if (searchValue.range === false && searchValue.exact) {
                  found = record[sKey] == searchValue.exact;
                }
              } else {
                found = record[sKey] == sValue;
              }
            }

            return found;
          });
        }

        results.forEach((record) => {
          searchShema.filter.forEach((filterKey) => {
            if (!filterOptions[filterKey] && record[filterKey]) {
              filterOptions[filterKey] = new Set();
            }

            if (filterOptions[filterKey]) {
              filterOptions[filterKey].add(record[filterKey]);
            }
          });
        });

        const searchResult: SearchResult = {
          searchedByShema: searchShema,
          results: results,
          filterOptions: filterOptions,
          totals: results.length,
        };
        return searchResult;
      })
    );
  }
}
