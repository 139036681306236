<div class="exception-body notfound-page">

    <div class="exception-top">
        <img src="assets/layout/images/icon-404.png" alt="poseidon-layout" />
    </div>

    <div class="exception-bottom">
        <div class="exception-wrapper">
            <span class="exception-summary">Page Not Found</span>
            <span class="exception-detail">The resource you are looking for does not exist.</span>
            <button pButton pRipple [routerLink]="['/']" label="Dashboard" icon="pi pi-home"></button>
            <a [routerLink]="['/']" class="logo">
                <img src="assets/layout/images/logo.svg" alt="poseidon-layout" class="logo-icon"  />
            </a>
            <span class="exception-footer">PrimeNG Poseidon</span>
        </div>
    </div>
</div>
