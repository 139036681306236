import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { DatasourcesService } from './administration/datasources/datasources.service';
import { PerspectivesService } from './administration/perspectives/perspectives.service';
import { JobsService } from './processing/jobs/jobs.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  topbarTheme = 'light';

  menuTheme = 'dim';

  layoutMode = 'light';

  menuMode = 'static';

  isRTL = false;

  inputStyle = 'outlined';

  ripple: boolean;

  constructor(
    private primengConfig: PrimeNGConfig,
    private perspectiveService: PerspectivesService,
    private datasourceService: DatasourcesService,
    private jobDefinitions: JobsService
  ) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.datasourceService.readAll().subscribe();
    this.perspectiveService.readAll().subscribe();
    this.jobDefinitions.readAll().subscribe();
  }
}
