<form [formGroup]="formGroup">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-fluid">
        <div class="p-field">
          <label for="name">Name</label>
          <input
            id="name"
            type="text"
            formControlName="name"
            type="text"
            pInputText
          />
        </div>
        <div class="p-field">
          <label for="description">Description</label>
          <input
            id="description"
            formControlName="description"
            type="text"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>
</form>

<div class="p-grid p-jc-end">
  <button
    pButton
    pRipple
    type="button"
    label="Save"
    (click)="onSectionFormDialogSave()"
    class="p-mr-2 p-mb-2"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Discard"
    (click)="onSectionFormDialogDiscard()"
    class="p-button-secondary p-mr-2 p-mb-2"
  ></button>
  &nbsp;&nbsp;
</div>
