<div class="p-grid">
  <div class="p-col-12">
    <div class="card">
      <p>&nbsp;</p>
      <div class="p-d-flex p-jc-center" style="text-align: center">
        <div class="p-mr-2 p-ai-center p-jc-center widget-feature-box">
          <i class="pi pi-desktop" style="font-size: 60pt"></i>
          <h2>Welcome to Radar - powerful analytical tool</h2>
          <span
            >This tool provides you with great capabilities to extract, load and
            search data from different sources in one data storage, 
            and search them as fast as blink of an eye.
          </span>
        </div>
      </div>
      <p>&nbsp;</p>
    </div>
  </div>
</div>
