import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractFormComponent } from '@radar-workspace/ui';
import { EnumUtility } from '@radar-workspace/utils';
import { ProcessingModuleConfig } from '../../processing.config';
import { JobsFeatureConfig } from '../job.feature';
import { JobsService } from '../jobs.service';
import {
  Job,
  ProccesingEngine,
  ProcessingOccurance,
  ProcessingStatus,
  ProcessingWeekDays,
} from '../job.model';
import { PerspectivesService } from '../../../administration/perspectives/perspectives.service';

@Component({
  selector: 'radar-workspace-perspectives-form',
  templateUrl: './jobs-form.component.html',
  styleUrls: ['./jobs-form.component.scss'],
})
export class JobsFormComponent
  extends AbstractFormComponent<Job>
  implements OnInit {
  engines = [];
  occurances = [];
  perspectives = [];
  weeklyDays = [];

  constructor(
    jobsService: JobsService,
    private perspectiveService: PerspectivesService,
    injector: Injector
  ) {
    super(jobsService, ProcessingModuleConfig, JobsFeatureConfig, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.engines = EnumUtility.mapEnumToSelectItems(ProccesingEngine);
    this.occurances = EnumUtility.mapEnumToSelectItems(ProcessingOccurance);
    this.weeklyDays = EnumUtility.mapEnumToSelectItems(ProcessingWeekDays);

    this.perspectiveService.readAll().subscribe(() => {
      this.perspectives = this.perspectiveService.getSelectItemList('name');
    });
  }

  initializeForm() {
    this.formGroup = new FormGroup({
      id: new FormControl(),
      status: new FormControl(ProcessingStatus.CREATED),
      perspective: new FormControl('', Validators.required),
      engine: new FormControl('', Validators.required),
      occurance: new FormControl('', Validators.required),
      summary: new FormControl('', Validators.required),
      startFrom: new FormControl('', Validators.required),
      hours: new FormControl('', Validators.required),
      weekly: new FormControl(''),
      monthly: new FormControl(''),
    });
  }
}
