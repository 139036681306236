<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card">
      <h5>
        Perspective: <strong>{{ perspective.name }}</strong>
      </h5>
      <p>{{ sc.featureConfig.featureDescription }}</p>

      <p-table
        [value]="gridRecords"
        dataKey="id"
        responsiveLayout="scroll"
        styleClass="p-datatable-sm"
        [reorderableColumns]="true"
        [expandedRowKeys]="expandedRows"
      >
        <ng-template pTemplate="caption">
          <div
            class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header"
          >
            <p-button
              pRipple
              type="button"
              label="Add section"
              (onClick)="onSectionCreate($event)"
              class="p-button-raised p-mr-2 p-mb-2"
            ></p-button>

            <div>
              <button
                pButton
                type="button"
                label="{{ isRowsExpanded ? 'Collapse rows' : 'Expand rows' }}"
                class="p-button-secondary"
                icon="{{
                  isRowsExpanded
                    ? 'pi pi-arrow-circle-up'
                    : 'pi pi-arrow-circle-down'
                }}"
                (click)="onToggleExpandableRows()"
              ></button>
              &nbsp;
              <button
                pButton
                type="button"
                label="Define mappings"
                class="p-button-secondary"
                icon="pi pi-sitemap"
                (click)="onShowMappings($event)"
              ></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem"></th>
            <th style="width: 3rem"></th>
            <th>Name</th>
            <th colspan="3">Description</th>
            <th style="width: 3rem"></th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-record
          let-expanded="expanded"
          let-index="rowIndex"
        >
          <tr [pReorderableRow]="index">
            <td>
              <span class="pi pi-bars" pReorderableRowHandle></span>
            </td>
            <td>
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="record"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></button>
            </td>
            <td>{{ record.name }}</td>
            <td colspan="3">{{ record.description }}</td>
            <td>
              <button
                (click)="onGridShowRowMenu($event, record)"
                pButton
                type="button"
                class="p-button-rounded p-button-secondary p-button-text"
                icon="pi pi-cog"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-record>
          <tr>
            <td colspan="7">
              <div class="p-p-3">
                <p-table
                  [value]="record.fields"
                  dataKey="key"
                  styleClass="p-datatable-sm"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 3rem"></th>
                      <th>Name</th>
                      <th>Key</th>
                      <th>Type</th>
                      <th>Appears in</th>
                      <th>Relations</th>
                      <th>Description</th>
                      <th style="width: 4rem"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-field let-index="rowIndex">
                    <tr [pReorderableRow]="index">
                      <td>
                        <span class="pi pi-bars" pReorderableRowHandle></span>
                      </td>
                      <td>{{ field.name }}</td>
                      <td>
                        <p-chip> {{ field.key }} </p-chip>
                      </td>
                      <td>
                        <p-tag
                          severity="info"
                          value="{{ field.type }}"
                          [rounded]="true"
                        ></p-tag>
                      </td>
                      <td>
                        <span
                          *ngFor="let block of field.appearsIn"
                          style="margin-right: 0.5rem"
                        >
                          <p-tag
                            *ngIf="block == 'data'"
                            severity="warning"
                            [rounded]="true"
                          >
                            <i class="pi pi-list" title="Data block"></i
                          ></p-tag>
                          <p-tag
                            *ngIf="block == 'search'"
                            severity="success"
                            [rounded]="true"
                          >
                            <i class="pi pi-search" title="Search block"></i
                          ></p-tag>
                          <p-tag
                            *ngIf="block == 'filter'"
                            severity="primary"
                            [rounded]="true"
                          >
                            <i class="pi pi-filter" title="Filter block"></i
                          ></p-tag>
                          <p-tag
                            *ngIf="block == 'global'"
                            severity="danger"
                            [rounded]="true"
                          >
                            <i class="pi pi-search" title="Global search"></i
                          ></p-tag>
                        </span>
                      </td>
                      <td>
                        <p-chip *ngIf="field.relations">
                          {{ field.relations.length }}
                        </p-chip>
                      </td>
                      <td>{{ field.description }}</td>
                      <td>
                        <button
                          (click)="onFieldShowRowMenu($event, record, field)"
                          pButton
                          type="button"
                          class="p-button-rounded p-button-secondary p-button-text"
                          icon="pi pi-ellipsis-v"
                        ></button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6">
                        There are no fields for this section yet.
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-menu #menu [popup]="true" [model]="gridMenuItems"></p-menu>
<p-menu #fieldMenu [popup]="true" [model]="fieldMenuItems"></p-menu>

<!-- <pre
  >{{ gridRecords | json }}
</pre>
 <pre>
  {{ expandedRows | json }}
</pre>-->
