<div class="login-body">
  <div class="login-wrapper">
    <div class="login-panel">
      <a [routerLink]="['/']" class="logo">
        <img
          src="assets/layout/images/radar-large-logo.png"
          alt="Radar - Powerfull analytical tool"
        />
      </a>
      <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
        <div class="login-form">
          <h2>Login To Your Account</h2>
          <p>
            Track your data with this best in class powerfull analytical tool
          </p>
          <p-messages [(value)]="msgs" [enableService]="true"></p-messages>
          <input
            type="text"
            autocomplete="off"
            pInputText
            placeholder="Username"
            formControlName="username"
          />
          <small
            id="type-help"
            class="p-error"
            *ngIf="
              !formGroup.controls['username'].valid &&
              formGroup.controls['username'].dirty
            "
            >Username is required.</small
          >
          <input
            type="password"
            autocomplete="off"
            pInputText
            placeholder="Password"
            formControlName="password"
          />
          <small
            id="type-help"
            class="p-error"
            *ngIf="
              !formGroup.controls['password'].valid &&
              formGroup.controls['password'].dirty
            "
            >Password is required.</small
          >
          <button pButton pRipple label="LOGIN" type="submit"></button>
        </div>
      </form>
      <p>A problem? <a href="#">Click here</a> and let us help you.</p>
    </div>
  </div>
</div>
