<form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <h4>Create new datasource</h4>
        <p>Create new datasource from which data would be gettered.</p>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <label for="name">Name</label>
            <input id="name" type="text" pInputText formControlName="name" />
            <small
              id="type-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['name'].valid &&
                formGroup.controls['name'].dirty
              "
              >Type is required.</small
            >
          </div>
          <div class="p-field p-col-12 p-md-9">
            <label for="description">Description</label>
            <input
              id="description"
              type="text"
              pInputText
              formControlName="description"
            />
            <small
              id="type-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['description'].valid &&
                formGroup.controls['description'].dirty
              "
              >Type is required.</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="connectionString">Connection string</label>
            <textarea
              id="address"
              type="text"
              rows="4"
              pInputTextarea
              formControlName="connectionString"
            ></textarea>
            <small
              id="type-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['connectionString'].valid &&
                formGroup.controls['connectionString'].dirty
              "
              >Type is required.</small
            >
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label for="type">Type</label>
            <p-dropdown
              inputId="type"
              [options]="types"
              placeholder="Select DB type"
              formControlName="type"
            ></p-dropdown>
            <small
              id="type-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['type'].valid &&
                formGroup.controls['type'].dirty
              "
              >Type is required.</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid p-jc-between">
    <div class="p-col-2">
      <button
        pButton
        pRipple
        type="submit"
        [disabled]="!formGroup.valid"
        label="Save"
      ></button>
    </div>
    <div class="p-col-1">
      <p-button
        pRipple
        type="button"
        label="Discard"
        (onClick)="onFormDiscard($event)"
        styleClass="p-button-secondary"
      ></p-button>
    </div>
  </div>
</form>
