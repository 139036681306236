<form [formGroup]="formGroup">
  <p-tabView>
    <p-tabPanel header="General data">
      <div class="p-grid">
        <div class="p-col-12">
          <div class="p-fluid">
            <div class="p-field">
              <label for="name">Name</label>
              <input
                id="name"
                type="text"
                formControlName="name"
                type="text"
                pInputText
              />
            </div>
            <div class="p-field">
              <label for="description">Description</label>
              <input
                id="description"
                formControlName="description"
                type="text"
                pInputText
              />
            </div>

            <div class="p-field">
              <label for="description">Type</label>
              <p-dropdown
                inputId="type"
                [options]="fieldTypes"
                placeholder="Select field type"
                formControlName="type"
              ></p-dropdown>
            </div>

            <div class="p-field">
              <label for="description">Key</label>
              <input id="key" formControlName="key" type="text" pInputText />
            </div>

            <h5>Appears in</h5>
            <div class="p-formgroup-inline">
              <div class="p-field-checkbox">
                <p-checkbox
                  name="appearsIn"
                  value="search"
                  inputId="sb"
                  [formControl]="formGroup.controls['appearsIn']"
                ></p-checkbox>
                <label for="sb">Search block</label>
              </div>

              <div class="p-field-checkbox">
                <p-checkbox
                  name="appearsIn"
                  value="filter"
                  inputId="fb"
                  [formControl]="formGroup.controls['appearsIn']"
                ></p-checkbox>
                <label for="fb">Filter block</label>
              </div>

              <div class="p-field-checkbox">
                <p-checkbox
                  name="appearsIn"
                  value="data"
                  inputId="db"
                  [formControl]="formGroup.controls['appearsIn']"
                ></p-checkbox>
                <label for="db">Data block</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox
                  name="appearsIn"
                  value="global"
                  inputId="gb"
                  [formControl]="formGroup.controls['appearsIn']"
                ></p-checkbox>
                <label for="db">Global search</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Relations">
      <div class="p-fluid" formGroupName="relation">
        <div class="p-field">
          <label for="relationName">Relation name</label>
          <input
            id="relationName"
            type="text"
            pInputText
            formControlName="name"
          />
        </div>
        <div class="p-field">
          <label for="perspective">Perspective</label>
          <p-dropdown
            [filter]="true"
            inputId="perspective"
            [options]="perspectives"
            formControlName="perspectiveId"
            placeholder="Select perspective"
            (onChange)="onPerspectiveSelected($event)"
          ></p-dropdown>
        </div>
        <div class="p-field">
          <label for="perspectiveField">Perspective field</label>
          <p-dropdown
            [filter]="true"
            inputId="perspectiveField"
            [options]="perspectiveFields"
            placeholder="Select perspective field"
            formControlName="perspectiveField"
          ></p-dropdown>
        </div>
      </div>

      <p-toolbar>
        <button
          pButton
          type="button"
          label="Add new relation"
          class="p-button-secondary"
          icon="pi pi-sitemap"
          (click)="onAddNewRelation()"
        ></button>
      </p-toolbar>

      <p-divider></p-divider>
      <ng-cotainer *ngFor="let chip of relations">
        <p-chip
          label="{{ chip.label }}"
          [removable]="true"
          (onRemove)="onRemoveChip(chip)"
        ></p-chip>
        <p-divider></p-divider>
      </ng-cotainer>
    </p-tabPanel>
  </p-tabView>
</form>

<div class="p-grid p-jc-end">
  <button
    pButton
    pRipple
    type="button"
    label="Save"
    (click)="onSectionFormDialogSave()"
    class="p-mr-2 p-mb-2"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Discard"
    (click)="onSectionFormDialogDiscard()"
    class="p-button-secondary p-mr-2 p-mb-2"
  ></button>
  &nbsp;&nbsp;
</div>
