import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@radar-workspace/ui';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Datasource } from './datasource.model';

@Injectable({
  providedIn: 'root',
})
export class DatasourcesService extends AbstractService<Datasource> {
  constructor(http: HttpClient) {
    super(http, 'assets/demo/data/datasources.json');
  }

  // getFieldList(datasourceId: string): Observable<SelectItem[]>{
  //     return this.readOne(datasourceId).pipe(
  //       map(datasource => {
  //         return datasource.fieldList.map(field => {
  //           return {
  //             label: field.label,
  //             value: field.id,
  //           };
  //         });
  //       })
  //     );
  // }
}
