import { ObjectUtility } from './object.utility';

export class ArrayUtil {
  static copyArray(array: any[]) {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    return ObjectUtility.copy(array);
  }

  static copyArrayAndAdd(array: any[], itemToAdd: any) {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    if (array == null) {
      return [itemToAdd];
    }

    const arrayCopy: any[] = this.copyArray(array); // eslint-disable-line @typescript-eslint/no-explicit-any
    arrayCopy.push(itemToAdd);

    return arrayCopy;
  }

  static deepCloneArray(array: any[]) {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    const clonedArray = [];
    array.map((item) => {
      clonedArray.push(Object.assign({}, item));
    });
    return clonedArray;
  }

  // eslint-disable-next-line max-len
  static copyArrayAndRemove(
    array: any[],
    itemToRemove: any,
    comparisonPropertyName?: string
  ): any[] {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    if (array == null) {
      return array;
    }
    const arrayCopy: any[] = this.copyArray(array); // eslint-disable-line @typescript-eslint/no-explicit-any
    this.removeFromArray(arrayCopy, itemToRemove, comparisonPropertyName);

    return arrayCopy;
  }

  // eslint-disable-next-line max-len
  static copyArrayAndRemoveByIndex(
    array: any[],
    indexOfItemToRemove: number
  ): any[] {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    if (array == null) {
      return array;
    }

    const arrayCopy: any[] = this.copyArray(array); // eslint-disable-line @typescript-eslint/no-explicit-any
    arrayCopy.splice(indexOfItemToRemove, 1);

    return arrayCopy;
  }

  /**
   * Removes item from array of objects or primitives.
   *
   * @param array source array from which an object or primitive should be removed
   * @param itemToRemove represents entire object that should be removed
   *  - a value of one of its fields or a primitive value
   * @param comparisonPropertyName name of the property which should be used to compare values
   *  - if the source array contains only objects
   */
  // eslint-disable-next-line max-len
  static removeFromArray(
    array: any[],
    itemToRemove: any,
    comparisonPropertyName?: string
  ): void {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    let itemIndex: number;

    if (comparisonPropertyName) {
      if (
        typeof itemToRemove === 'object' &&
        itemToRemove.constructor === Object
      ) {
        itemIndex = array.findIndex(
          (item) =>
            item[comparisonPropertyName] ===
            itemToRemove[comparisonPropertyName]
        );
      } else {
        itemIndex = array.findIndex(
          (item) => item[comparisonPropertyName] === itemToRemove
        );
      }
    } else {
      itemIndex = array.findIndex((item) => item === itemToRemove);
    }

    if (itemIndex !== -1) {
      array.splice(itemIndex, 1);
    }
  }
}
