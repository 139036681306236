import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';

@NgModule({
  imports: [CommonModule, ConfirmDialogModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
  providers: [BreadcrumbService, ConfirmationService, MessageService],
})
export class UiModule {}
