<p-dropdown
  [options]="perspectiveDropdownValues"
  (onChange)="predefinedPerspective = $event.value"
></p-dropdown>
&nbsp;&nbsp;
<button
  pButton
  type="button"
  class="p-button-outlined p-button-rounded p-button-sm"
  icon="pi pi-plus"
  (click)="onAddNewSearchTab()"
></button>
<p-divider></p-divider>

<p-tabView [(activeIndex)]="activeTabIndex" (onClose)="onSearcTabClose($event)">
  <ng-container
    *ngFor="
      let item of searchTabs;
      let i = index;
      let last = last;
      let first = first
    "
  >
    <p-tabPanel
      [selected]="i == activeTabIndex"
      [header]="item.perspective.name"
      [closable]="!first"
    >
      <div class="p-grid">
        <div class="p-col-12">
          <div class="card">
            <form
              [formGroup]="searchTabs[i].searchFormGroup"
              (ngSubmit)="onSearchButtonClicked()"
            >
              <p-panel
                [toggleable]="true"
                [collapsed]="searchTabs[i].girdVisible"
              >
                <ng-template pTemplate="header">
                  {{ sc.featureConfig.featureName }}
                  <div>
                    <ng-cotainer
                      *ngFor="let chip of searchTabs[i].searchChipsValues"
                    >
                      <p-chip
                        label="{{ chip.value }}"
                        [removable]="true"
                        (onRemove)="onRemoveChip(chip.key)"
                      ></p-chip
                      >&nbsp;
                    </ng-cotainer>
                  </div>
                </ng-template>

                <p>{{ sc.featureConfig.featureDescription }}</p>
                <p-toolbar>
                  <div class="p-toolbar-group-left">
                    <p-dropdown
                      [options]="perspectiveDropdownValues"
                      (onChange)="onChangePerspective($event, i)"
                      formControlName="perspectiveId"
                    ></p-dropdown>
                  </div>
                </p-toolbar>

                <p-tabView
                  *ngIf="searchTabs[i].perspective"
                  orientation="left"
                  formGroupName="search"
                >
                  <ng-container
                    *ngFor="
                      let section of Object.keys(
                        searchTabs[i].searchBlockFields
                      )
                    "
                  >
                    <p-tabPanel header="{{ section }}">
                      <div class="p-fluid p-formgrid p-grid">
                        <ng-container
                          *ngFor="
                            let field of searchTabs[i].searchBlockFields[
                              section
                            ]
                          "
                        >
                          <!-- export enum FieldType {
                          Text = 'Text',
                          Keyword = 'Keyword',
                          Text_Keyword = 'Text+Keyword',
                          Date = 'Date',
                          Long = 'Long',
                          Integer = 'Integer',
                          Short = 'Short',
                          Byte = 'Byte',
                          Double = 'Double',
                          Float = 'Float',
                          Half_Float = 'Half Float',
                          Scaled_Float = 'Scaled Float',
                        } -->

                          <!-- check is field for search blocks -->
                          <div class="p-field p-col-12 p-md-6">
                            <label for="{{ field.key }}">{{
                              field.name
                            }}</label>
                            <input
                              *ngIf="
                                [
                                  'text',
                                  'keyword',
                                  'text_keyword',
                                  'byte'
                                ].includes(field.type.toLowerCase())
                              "
                              id="{{ field.key }}"
                              type="text"
                              pInputText
                              formControlName="{{ field.key }}"
                            />

                            <div
                              *ngIf="
                            [
                              'bigint',
                              'long',
                              'integer',
                              'short',
                              'double',
                            ].includes(field.type.toLowerCase())
                          "
                              class="p-inputgroup"
                              formGroupName="{{ field.key }}"
                            >
                              <span class="p-inputgroup-addon"
                                ><p-checkbox
                                  title="Normal or range input mode"
                                  binary="true"
                                  trueValue="Range"
                                  falseValue="Normal"
                                  formControlName="range"
                                  (onChange)="
                                    searchTabs[i].normalRangeFieldMap[
                                      field.key
                                    ] = $event.checked
                                  "
                                ></p-checkbox></span
                              ><input
                                [hidden]="
                                  searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-exact"
                                type="text"
                                pInputText
                                pKeyFilter="num"
                                formControlName="exact"
                              />
                              <input
                                [hidden]="
                                  !searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-from"
                                type="text"
                                pInputText
                                pKeyFilter="int"
                                placeholder="From range"
                                formControlName="from"
                              />
                              <input
                                [hidden]="
                                  !searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-to"
                                type="text"
                                pInputText
                                pKeyFilter="int"
                                placeholder="To range"
                                formControlName="to"
                              />
                            </div>

                            <div
                              *ngIf="
                            [
                            'float',
                            'half_float',
                            'scaled_float',
                            ].includes(field.type.toLowerCase())
                          "
                              class="p-inputgroup"
                              formGroupName="{{ field.key }}"
                            >
                              <span class="p-inputgroup-addon"
                                ><p-checkbox
                                  title="Normal or range input mode"
                                  binary="true"
                                  trueValue="Range"
                                  falseValue="Normal"
                                  formControlName="range"
                                  (onChange)="
                                    searchTabs[i].normalRangeFieldMap[
                                      field.key
                                    ] = $event.checked
                                  "
                                ></p-checkbox></span
                              ><input
                                [hidden]="
                                  searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-exact"
                                type="text"
                                pInputText
                                pKeyFilter="num"
                                formControlName="exact"
                                mode="decimal"
                              />
                              <input
                                [hidden]="
                                  !searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-from"
                                type="text"
                                pInputText
                                pKeyFilter="num"
                                placeholder="From range"
                                formControlName="from"
                                mode="decimal"
                              />
                              <input
                                [hidden]="
                                  !searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-to"
                                type="text"
                                pInputText
                                pKeyFilter="num"
                                placeholder="To range"
                                formControlName="to"
                                mode="decimal"
                              />
                            </div>

                            <div
                              *ngIf="
                          [
                          'date',
                          ].includes(field.type.toLowerCase())
                        "
                              class="p-inputgroup"
                              formGroupName="{{ field.key }}"
                            >
                              <span class="p-inputgroup-addon"
                                ><p-checkbox
                                  title="Normal or range input mode"
                                  binary="true"
                                  trueValue="Range"
                                  formControlName="range"
                                  falseValue="Normal"
                                  (onChange)="
                                    searchTabs[i].normalRangeFieldMap[
                                      field.key
                                    ] = $event.checked
                                  "
                                ></p-checkbox></span
                              ><p-calendar
                                [hidden]="
                                  searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-exact"
                                type="text"
                                formControlName="exact"
                                dateFormat="dd.mm.yy"
                              ></p-calendar>
                              <p-calendar
                                [hidden]="
                                  !searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-from"
                                placeholder="From range"
                                formControlName="from"
                                dateFormat="dd.mm.yy"
                              ></p-calendar>
                              <p-calendar
                                [hidden]="
                                  !searchTabs[i].normalRangeFieldMap[field.key]
                                "
                                id="{{ field.key }}-to"
                                placeholder="To range"
                                formControlName="to"
                                dateFormat="dd.mm.yy"
                              ></p-calendar>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </p-tabPanel>
                  </ng-container>
                </p-tabView>

                <p-divider></p-divider>

                <div class="p-d-flex p-jc-between">
                  <div>
                    <button
                      pButton
                      class="p-text-left"
                      type="submit"
                      label="Search"
                      icon="pi pi-search"
                    ></button>
                  </div>
                  <div>
                    <!-- <button
                      pButton
                      class="p-text-right"
                      type="button"
                      label="Save as template"
                      class="p-button-secondary"
                      icon="pi pi-bookmark"
                    ></button>
                    &nbsp; -->
                    <button
                      pButton
                      class="p-button-secondary p-text-right"
                      type="button"
                      label="Clear search form"
                      icon="pi pi-refresh"
                      (click)="searchTabs[i].searchFormGroup.reset()"
                    ></button>
                    &nbsp;
                    <button
                      pButton
                      class="p-button-secondary p-text-right"
                      type="button"
                      label="Clear results"
                      icon="pi pi-refresh"
                      (click)="onClearResultsButtonClicked()"
                    ></button>
                  </div>
                </div>
              </p-panel>
            </form>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="searchTabs[i].girdVisible">
        <p-progressBar
          *ngIf="searchTabs[i].loadingProgress"
          mode="indeterminate"
          [style]="{ height: '6px' }"
        ></p-progressBar>
        <p-table
          #dt
          [value]="searchTabs[i].gridRecords"
          [(selection)]="gridRecordsSelected"
          dataKey="id"
          styleClass="p-datatable-customers"
          [rowHover]="true"
          [rows]="10"
          [paginator]="true"
          [scrollable]="true"
          [resizableColumns]="true"
          [responsive]="true"
        >
          <ng-template pTemplate="caption">
            <div
              class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header"
            >
              <div class="p-toolbar-group-left">
                <ng-container
                  *ngFor="let section of searchTabs[i].perspective.sections"
                >
                  <ng-container *ngFor="let field of section.fields">
                    <ng-container *ngIf="field.appearsIn">
                      <ng-container *ngIf="field.appearsIn.includes('filter')">
                        <p-dropdown
                          [options]="
                            searchTabs[i].filterBlockDropdownValues[field.key]
                          "
                          placeholder="{{ field.name }}"
                          [showClear]="true"
                          (onChange)="onFilterDropdownChange($event, field.key)"
                        ></p-dropdown>
                        &nbsp;
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

              <div class="p-toolbar-group-right">
                <button
                  pButton
                  type="button"
                  label="Export XLSX"
                  class="p-button-secondary"
                  icon="pi pi-file-excel"
                  (click)="exportExcel()"
                ></button>
                &nbsp;
                <button
                  pButton
                  type="button"
                  label="Clear results"
                  class="p-button-secondary"
                  icon="pi pi-refresh"
                  (click)="onClearResultsButtonClicked()"
                ></button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>

              <ng-container *ngFor="let field of searchTabs[i].dataBlockFields">
                <th pSortableColumn="{{ field.key }}">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{ field.name }}
                    <p-sortIcon field="{{ field.key }}"></p-sortIcon>
                  </div>
                </th>
              </ng-container>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-record>
            <tr class="p-selectable-row" (click)="onRowSelect(record)">
              <td>
                <p-tableCheckbox [value]="record"></p-tableCheckbox>
              </td>

              <ng-container *ngFor="let field of searchTabs[i].dataBlockFields">
                <td class="ui-resizable-column">
                  {{ record[field.key] }}
                  <span *ngIf="field.relations">
                    <i class="pi pi-link"></i>

                    <!-- <button
                      (click)="onRelataionLinkSelected(record[field.key], field)"
                      pButton
                      type="button"
                      class="p-button-rounded p-button-secondary p-button-text"
                      icon="pi pi-cog"
                    ></button> -->
                  </span>
                </td>
              </ng-container>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3">No search resulst found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
  </ng-container>
</p-tabView>

<p-sidebar
  *ngIf="searchTabs[activeTabIndex]"
  [(visible)]="isSidebarVisible"
  [modal]="false"
  position="right"
  [style]="{ width: '50em' }"
>
  <ng-container *ngIf="searchTabs[activeTabIndex].perspective">
    <ng-container
      *ngFor="let section of searchTabs[activeTabIndex].perspective.sections"
    >
      <div class="p-grid p-nogutter">
        <div class="p-col-12">
          <h4>{{ section.name }}</h4>
        </div>
        <div *ngFor="let field of section.fields" class="p-col-6">
          <strong>{{ field.name }}</strong
          >:
          <span *ngIf="!field.relations">{{ recordClicked[field.key] }}</span>

          <span *ngIf="field.relations" style="cursor: pointer">
            <p-tag
              (click)="
                onRelataionLinkSelected($event, recordClicked[field.key], field)
              "
              value="{{ recordClicked[field.key] }}"
            ></p-tag
          ></span>
        </div>
      </div>
      <p-divider></p-divider>
    </ng-container>
  </ng-container>
</p-sidebar>

<p-menu #fieldMenu [popup]="true" [model]="fieldMenuItems"></p-menu>

<!-- p-button-raised p-button-rounded -->

<!-- <pre>
  {{ gridRecords | json}}
</pre>
<pre>
  {{ searchBlockFields | json}}
</pre>
<pre>
  {{ dataBlockFields | json}}
</pre>

<pre>
  {{ searchFormGroup.value | json}}
</pre>

<pre>
  {{ searchChipsValues | json}}
</pre>

<pre>
  {{ searchShema | json}}
</pre>

<pre>
  {{ filterBlockDropdownValues | json}}
</pre>
<pre>
  {{ activeTabIndex | json }}
</pre>
<pre>
  {{ searchTabs[activeTabIndex].searchFormGroup.value | json }}
</pre>

-->
