import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatasourcesGridComponent } from './datasources/datasources-grid/datasources-grid.component';
import { DatasourcesFormComponent } from './datasources/datasources-form/datasources-form.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { PerspectivesGridComponent } from './perspectives/perspectives-grid/perspective-grid.component';
import { PerspectivesFormComponent } from './perspectives/perspectives-form/perspectives-form.component';
import { SectionGridComponent } from './sections/section-grid/section-grid.component';
import { SectionFormComponent } from './sections/section-form/section-form.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldFormComponent } from './sections/field-form/field-form.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { MappingsFormComponent } from './perspectives/mappings-form/mappings-form.component';
import { DividerModule } from 'primeng/divider';
import { ToolbarModule } from 'primeng/toolbar';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [
    DatasourcesGridComponent,
    DatasourcesFormComponent,
    PerspectivesGridComponent,
    PerspectivesFormComponent,
    SectionGridComponent,
    SectionFormComponent,
    FieldFormComponent,
    MappingsFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    MenuModule,
    DynamicDialogModule,
    CheckboxModule,
    TagModule,
    ChipModule,
    DividerModule,
    ToolbarModule,
    TabViewModule,
  ],
})
export class AdministrationModule {}
