import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractFormComponent } from '@radar-workspace/ui';
import { EnumUtility } from '@radar-workspace/utils';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GalleriaThumbnails } from 'primeng/galleria';
import { AdministrationModuleConfig } from '../../administration.config';
import { Perspective } from '../../perspectives/perspective.model';
import { PerspectivesService } from '../../perspectives/perspectives.service';
import { SectionFeatureConfig } from '../section.feature';
import { Field, FieldType, Section } from '../section.model';
import { SectionsService } from '../sections.service';

@Component({
  selector: 'radar-workspace-field-form',
  templateUrl: './field-form.component.html',
  styleUrls: ['./field-form.component.scss'],
})
export class FieldFormComponent
  extends AbstractFormComponent<Section>
  implements OnInit {
  fieldTypes = [];
  relations = [];
  ownPerspective: Perspective;
  perspectives = [];
  perspectiveFields = [];
  selectedPerspective: Perspective;
  selectedField: Field;
  selectedRelationName: string;
  fieldKeyMap: Map<string, Field>;

  constructor(
    private sectionsService: SectionsService,
    injector: Injector,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private perspectiveService: PerspectivesService
  ) {
    super(
      sectionsService,
      AdministrationModuleConfig,
      SectionFeatureConfig,
      injector
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.fieldTypes = EnumUtility.mapEnumToSelectItems(FieldType);

    if (this.config.data && this.config.data.id) {
      this.formMode = 'edit';
    } else {
      this.formMode = 'create';
    }

    this.perspectiveService.readAll().subscribe((perspectives) => {
      this.perspectives = this.perspectiveService.getSelectItemList('name');
    });

    this.sectionsService.perspective$.subscribe((perspective) => {
      this.ownPerspective = perspective;
    });
  }

  initializeForm() {
    this.formGroup = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      key: new FormControl('', Validators.required),
      appearsIn: new FormControl(''),
      relation: new FormGroup({
        name: new FormControl(''),
        perspectiveId: new FormControl(''),
        perspectiveField: new FormControl(''),
      }),
    });
  }

  populateForm() {
    let id = null;

    if (this.config.data && this.config.data.id) {
      this.formMode = 'edit';
      id = this.config.data.id;
    } else {
      this.formMode = 'create';
    }

    if (id) {
      this.formMode = 'edit';

      this.sectionsService
        .readFieldById(this.config.data.sectionId, id)
        .subscribe((record) => {
          this.relations = record.relations;
          this.formModelData = record;
          this.formGroup.patchValue(record);
        });
    }
  }

  onSectionFormDialogSave(): void {
    const field = this.formGroup.value as Field;
    delete field['relation'];
    field.relations = this.relations;

    if (this.formMode == 'create') {
      this.sectionsService
        .addField(this.config.data.sectionId, field)
        .subscribe((data) => {
          this.ref.close(data);
        });
    } else if (this.formMode == 'edit') {
      this.sectionsService
        .updateField(this.config.data.sectionId, field)
        .subscribe((data) => {
          this.ref.close(data);
        });
    }
  }

  onSectionFormDialogDiscard(): void {
    this.ref.close(null);
  }

  onPerspectiveSelected(event: SelectItem) {
    this.perspectiveService.readOne(event.value).subscribe((data) => {
      this.selectedPerspective = data;
      this.loadPerspectiveFields();
    });
  }

  loadPerspectiveFields() {
    this.perspectiveFields = [];
    this.fieldKeyMap = new Map<string, Field>();

    this.selectedPerspective.sections.forEach((section) => {
      const fields = section.fields.map((field) => {
        this.fieldKeyMap.set(field.key, field);
        return {
          value: field.key,
          label: field.name,
        };
      });

      this.perspectiveFields = [...this.perspectiveFields, ...fields];
    });
  }

  onRemoveChip(value: any) {
    this.relations = this.relations.filter(
      (relation) => relation.label !== value.label
    );
  }

  onAddNewRelation() {
    const relation = this.formGroup.value.relation;
    relation[
      'label'
    ] = `${this.ownPerspective.name}.${this.formGroup.value.key} -- ${relation.name} --> ${this.selectedPerspective.name}.${relation.perspectiveField}`;
    if (!this.relations) this.relations = [];
    this.relations.push(relation);
    this.formGroup.get('relation').patchValue({
      name: '',
      perspectiveId: '',
      perspectiveField: '',
    });
  }
}
