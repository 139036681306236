import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractFormComponent } from '@radar-workspace/ui';
import { SelectItem } from 'primeng/api';
import { AdministrationModuleConfig } from '../../administration.config';
import { DatasourcesService } from '../../datasources/datasources.service';
import { PerspectiveFeatureConfig } from '../perspective.feature';
import { Perspective } from '../perspective.model';
import { PerspectivesService } from '../perspectives.service';

@Component({
  selector: 'radar-workspace-perspectives-form',
  templateUrl: './perspectives-form.component.html',
  styleUrls: ['./perspectives-form.component.scss'],
})
export class PerspectivesFormComponent
  extends AbstractFormComponent<Perspective>
  implements OnInit {
  datasources: SelectItem[];

  constructor(
    perspectivesService: PerspectivesService,
    injector: Injector,
    private datasourcesService: DatasourcesService
  ) {
    super(
      perspectivesService,
      AdministrationModuleConfig,
      PerspectiveFeatureConfig,
      injector
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.datasources = this.datasourcesService.getSelectItemList('name');
  }

  onFormSubmit() {
    if (this.formMode == 'create') {
      const createdPerspective = this.formGroup.value as Perspective;
      createdPerspective['sections'] = [];

      this.service.create(createdPerspective).subscribe((data) => {
        this.router.navigate(
          [
            '/' +
              this.sc.moduleConfig.moduleUrlPrefix +
              '/' +
              this.sc.featureConfig.featureUrlPrefix,
          ],
          {
            relativeTo: this.route,
          }
        );
      });
    } else if (this.formMode == 'edit') {
      this.service
        .update(this.formGroup.value as Perspective)
        .subscribe((data) => {
          this.router.navigate(
            [
              '/' +
                this.sc.moduleConfig.moduleUrlPrefix +
                '/' +
                this.sc.featureConfig.featureUrlPrefix,
            ],
            {
              relativeTo: this.route,
            }
          );
        });
    }
  }

  initializeForm() {
    this.formGroup = new FormGroup({
      id: new FormControl(''),
      datasourceId: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });
  }
}
