import { Component } from '@angular/core';
import { BreadcrumbService } from '@radar-workspace/ui';

@Component({
  templateUrl: './app.help.component.html',
})
export class AppHelpComponent {
  text: any;

  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Home', routerLink: ['/'] },
      { label: 'Help', routerLink: ['/help'] },
    ]);
  }
}
