import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractGridComponent } from '@radar-workspace/ui';
import { Perspective } from '../../../administration/perspectives/perspective.model';
import { PerspectivesService } from '../../../administration/perspectives/perspectives.service';
import { SectionsService } from '../../../administration/sections/sections.service';
import { AnalyticsModuleConfig } from '../../analytics.config';
import { SearchFeatureConfig } from '../search.feature';
import { SearchResult, SearchShema } from '../search.model';
import { SearchService } from '../search.service';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import {
  Field,
  Relation,
} from '../../../administration/sections/section.model';
import { Menu } from 'primeng/menu';
import { SearchHistoryService } from '../../search-history/search-history.service';
import { Location } from '@angular/common';
import { format } from 'date-fns';

export class SearchTab {
  girdVisible = false;
  filtersVisible = true;
  searchFormGroup: FormGroup;
  perspective: Perspective = null;
  searchShema: SearchShema = {
    perspectiveId: null,
    search: {},
    filter: [],
  };
  searchBlockFields = {};
  filterBlockFields = [];
  dataBlockFields = [];

  searchChipsValues = [];
  fromKeyNameMap = {};
  fromKeyFieldMap = {};
  filterBlockDropdownValues = {};
  filterBlockDropdownSelectedValues = {};
  gridRecords = [];
  loadingProgress = false;
  normalRangeFieldMap = {};
}

@Component({
  selector: 'radar-workspace-search-grid',
  templateUrl: './search-grid.component.html',
  styleUrls: ['./search-grid.component.scss'],
})
export class SearchGridComponent
  extends AbstractGridComponent<SearchResult>
  implements OnInit {
  Object = Object;

  activeTabIndex = 0;
  searchTabs: SearchTab[] = [];
  perspectives = [];
  perspectiveDropdownValues = [];
  predefinedPerspective = null;

  @ViewChild('fieldMenu') fieldRecordMenu: Menu;
  fieldMenuItems: MenuItem[];

  /** Sidebar for showing active data */
  recordClicked = {};
  isSidebarVisible = false;
  selectedPerspective: Perspective;

  constructor(
    private searchService: SearchService,
    injector: Injector,
    private perspectiveService: PerspectivesService,
    private sectionsService: SectionsService,
    private formBuilder: FormBuilder,
    private searchHistoryService: SearchHistoryService,
    private location: Location
  ) {
    super(searchService, AnalyticsModuleConfig, SearchFeatureConfig, injector);
  }

  ngOnInit() {
    this.setBreadcrumbs();

    this.perspectiveService.readAll().subscribe((perspectives) => {
      this.perspectives = perspectives;
      this.perspectiveDropdownValues = this.perspectiveService.getSelectItemList(
        'name'
      );

      this.route.queryParams.subscribe((data: any) => {
        const searchShema = data.searchShema
          ? JSON.parse(data.searchShema)
          : null;

        if (searchShema) {
          this.addAndActivatePerspective(
            this.perspectives.find(
              (perspective) => perspective.id == searchShema.perspectiveId
            ),
            searchShema
          );
          this.setActiveTabToLatest();
          this.onChangePerspective(
            { value: searchShema.perspectiveId },
            this.searchTabs.length - 1
          );
          this.populateSearchDataFromSearchShema(searchShema);
          this.onSearchButtonClicked();
        } else {
          this.onAddNewSearchTab();
        }
      });
    });
  }

  onSearcTabClose(event: Event) {
    this.activeTabIndex = 0;
  }

  onAddNewSearchTab() {
    const perspectiveId = this.predefinedPerspective
      ? this.predefinedPerspective
      : this.perspectives[0].id;

    const perspective = this.perspectives.find(
      (perspective) => perspective.id == perspectiveId
    );

    if (perspective) {
      this.addAndActivatePerspective(perspective);
      this.setActiveTabToLatest();
      this.onChangePerspective(
        { value: perspective.id },
        this.searchTabs.length - 1
      );
    }
  }

  addAndActivatePerspective(
    perspective: Perspective,
    searchShema?: SearchShema
  ) {
    const searchTab = new SearchTab();
    searchTab.perspective = perspective;

    if (searchShema) {
      searchTab.searchShema = searchShema;
    }

    this.searchTabs.push(searchTab);
  }

  populateSearchDataFromSearchShema(searchShema: SearchShema) {
    this.searchTabs[this.activeTabIndex].searchFormGroup.patchValue(
      searchShema
    );
  }

  setActiveTabToLatest() {
    this.activeTabIndex = this.searchTabs.length - 1;
  }

  onChangePerspective(event: any, index: number) {
    this.sectionsService.setPerspectiveById(event.value);

    this.sectionsService.perspective$.subscribe((data) => {
      this.searchTabs[this.activeTabIndex].perspective = data;
      this.loadBlockFields(data);
    });
  }

  loadBlockFields(perspective: Perspective) {
    this.searchTabs[this.activeTabIndex].searchBlockFields = {};
    this.searchTabs[this.activeTabIndex].filterBlockFields = [];
    this.searchTabs[this.activeTabIndex].dataBlockFields = [];

    const sfg = {};

    perspective.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.appearsIn) {
          if (field.appearsIn.includes('search')) {
            if (
              !this.searchTabs[this.activeTabIndex].searchBlockFields[
                section.name
              ]
            )
              this.searchTabs[this.activeTabIndex].searchBlockFields[
                section.name
              ] = [];

            this.searchTabs[this.activeTabIndex].searchBlockFields[
              section.name
            ].push(field);
            sfg[field.key] = this.createFormGroupOrField(field);
            this.searchTabs[this.activeTabIndex].fromKeyNameMap[field.key] =
              field.name;
            this.searchTabs[this.activeTabIndex].fromKeyFieldMap[
              field.key
            ] = field;
          }

          if (field.appearsIn.includes('filter')) {
            this.searchTabs[this.activeTabIndex].filterBlockFields.push(field);
            this.searchTabs[this.activeTabIndex].filterBlockDropdownValues[
              field.key
            ] = [];
          }

          if (field.appearsIn.includes('data')) {
            this.searchTabs[this.activeTabIndex].dataBlockFields.push(field);
          }
        }
      });
    });

    this.searchTabs[
      this.activeTabIndex
    ].searchFormGroup = this.formBuilder.group({
      perspectiveId: this.formBuilder.control(perspective.id),
      search: this.formBuilder.group(sfg),
    });
  }

  createFormGroupOrField(field: Field) {
    const rangeableFields = [
      'bigint',
      'long',
      'integer',
      'short',
      'double',
      'float',
      'half_float',
      'scaled_float',
      'date',
    ];
    this.searchTabs[this.activeTabIndex].normalRangeFieldMap[field.key] = '';

    if (rangeableFields.includes(field.type.toLowerCase())) {
      return this.formBuilder.group({
        range: this.formBuilder.control(false),
        from: this.formBuilder.control(''),
        to: this.formBuilder.control(''),
        exact: this.formBuilder.control(''),
        type: this.formBuilder.control(field.type),
      });
    } else {
      return this.formBuilder.control('');
    }
  }

  onSearchButtonClicked() {
    this.searchTabs[this.activeTabIndex].girdVisible = true;
    this.searchTabs[this.activeTabIndex].filtersVisible = false;

    this.populateChipsWithSerachFields();
    this.doSearch();
  }

  onClearResultsButtonClicked() {
    this.searchTabs[this.activeTabIndex].girdVisible = false;
    this.searchTabs[this.activeTabIndex].filtersVisible = true;

    this.searchTabs[this.activeTabIndex].gridRecords = [];
    this.searchTabs[this.activeTabIndex].searchShema.search = {};
    this.searchTabs[this.activeTabIndex].filterBlockDropdownSelectedValues = [];
  }

  onFilterDropdownChange($event: any, key: string) {
    if ($event.value && $event.value.id)
      this.searchTabs[this.activeTabIndex].filterBlockDropdownSelectedValues[
        key
      ] = $event.value.id;
    else
      delete this.searchTabs[this.activeTabIndex]
        .filterBlockDropdownSelectedValues[key];

    this.doSearch();
  }

  onRowSelect(record: Object) {
    this.recordClicked = record;
    this.isSidebarVisible = true;
  }

  onRelataionLinkSelected(event: Event, value: any, field: Field) {
    console.log('onRelataionLinkSelected', value, field);
    const menuItems = [];

    if (!field || !field.relations) return;

    field.relations.forEach((relation: Relation) => {
      menuItems.push({
        label: relation.name,
        command: () => this.openRelationTab(relation, value, field),
      });
    });

    this.fieldMenuItems = menuItems;
    this.fieldRecordMenu.toggle(event);
  }

  openRelationTab(relation: Relation, value: any, field: Field) {
    console.log('openRelationTab', relation, value, field);

    this.perspectiveService
      .readOne(relation.perspectiveId)
      .subscribe((perspective: Perspective) => {
        const searchSheme = {
          perspectiveId: perspective.id,
          search: {},
          filter: [],
        };

        searchSheme.search[relation.perspectiveField] = value;

        this.addAndActivatePerspective(perspective, searchSheme);
        this.setActiveTabToLatest();
        this.onChangePerspective(
          { value: perspective.id },
          this.searchTabs.length - 1
        );

        this.populateSearchDataFromSearchShema(searchSheme);
        this.isSidebarVisible = false;
        this.onSearchButtonClicked();
      });
  }

  doSearch() {
    this.searchTabs[this.activeTabIndex].loadingProgress = true;

    this.searchTabs[
      this.activeTabIndex
    ].searchShema.perspectiveId = this.searchTabs[
      this.activeTabIndex
    ].perspective.id;

    this.searchTabs[this.activeTabIndex].searchShema.search = {
      ...this.searchTabs[this.activeTabIndex].filterBlockDropdownSelectedValues,
    };

    this.searchTabs[this.activeTabIndex].searchShema.filter = this.searchTabs[
      this.activeTabIndex
    ].filterBlockFields.map((field) => field.key);

    for (const [key, v] of Object.entries(
      this.searchTabs[this.activeTabIndex].searchFormGroup.value.search
    )) {
      if (v) this.searchTabs[this.activeTabIndex].searchShema.search[key] = v;
    }

    const searchHistory = {
      username: 'john.doe',
      searchCriteria: JSON.stringify(
        this.searchTabs[this.activeTabIndex].searchShema
      ),
      started: new Date(),
      finished: new Date(),
    };

    this.searchService
      .doSearch(this.searchTabs[this.activeTabIndex].searchShema)
      .subscribe((results) => {
        this.searchTabs[this.activeTabIndex].gridRecords = results.results;

        this.searchTabs[this.activeTabIndex].filterBlockFields.forEach(
          (field) => {
            if (results.filterOptions[field.key]) {
              const values = Array.from(results.filterOptions[field.key]);

              this.searchTabs[this.activeTabIndex].filterBlockDropdownValues[
                field.key
              ] = values.map((value) => {
                return {
                  id: value,
                  label: value,
                };
              });
            }
          }
        );

        this.searchTabs[this.activeTabIndex].loadingProgress = false;

        searchHistory.finished = new Date();
        this.searchHistoryService.create(searchHistory);
      });
  }

  /**
   * ////////////////////// CHIP - LOGIC /////////////////////////////
   */

  populateChipsWithSerachFields() {
    const value = this.searchTabs[this.activeTabIndex].searchFormGroup.value;
    const searchChipsValues = [];

    for (const [key, val] of Object.entries(value.search)) {
      if (val) {
        let label = null;

        if (typeof val === 'object') {
          const v = val as any;
          if (v.range) {
            const v = val as any;

            if (
              this.searchTabs[this.activeTabIndex].fromKeyFieldMap[
                key
              ].type.toLowerCase() == 'date'
            ) {
              label =
                format(new Date(v.from), 'DD.MM.YYYY') +
                ' - ' +
                format(new Date(v.to), 'DD.MM.YYYY');
            } else {
              label = v.from + ' - ' + v.to;
            }
          } else {
            label = v.exact;
          }
        } else {
          label = val as string;
        }

        if (label) {
          searchChipsValues.push({
            key: key,
            value: `${
              this.searchTabs[this.activeTabIndex].fromKeyNameMap[key]
            }: ${label}`,
          });
        }
      }
    }

    this.searchTabs[this.activeTabIndex].searchChipsValues = searchChipsValues;
  }

  onRemoveChip(formFieldKey: any) {
    this.searchTabs[this.activeTabIndex].searchFormGroup
      .get('search')
      .get(formFieldKey)
      .reset();

    this.doSearch();
  }

  /**
   *  //////////////////////// EXPORT //////////////////////////////////
   */

  exportPdf() {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        // const doc = new jsPDF.default(0,0);
        // doc.autoTable(this.exportColumns, this.products);
        // doc.save('products.pdf');
      });
    });
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        this.searchTabs[this.activeTabIndex].gridRecords
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'products');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
