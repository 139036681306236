export class ObjectUtility {
  /**
   * Recursively deep copies object with any type
   * @param obj
   */
  // The linting rules are here because this is a utility method
  // which performs deep copy on any type of array. And ESlint argues with that.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static copy(obj: any[] | any) {
    if (obj == null) {
      return obj;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const arrayCopy: any[] | any = Array.isArray(obj) ? [] : {};
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      arrayCopy[key] =
        typeof value === 'object' && value !== null ? this.copy(value) : value;
    }
    return arrayCopy;
  }

  /**
   * Check is paramater object
   * @param obj
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isObject(obj: any) {
    return obj === Object(obj);
  }
}
