import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Datasource, DatasourceType } from '../datasource.model';
import { DatasourcesService } from '../datasources.service';
import { EnumUtility } from '@radar-workspace/utils';
import { AbstractFormComponent } from '@radar-workspace/ui';
import { AdministrationModuleConfig } from '../../administration.config';
import { DatasourceFeatureConfig } from '../datasource.feature';

@Component({
  selector: 'radar-workspace-datasources-form',
  templateUrl: './datasources-form.component.html',
  styleUrls: ['./datasources-form.component.scss'],
})
export class DatasourcesFormComponent
  extends AbstractFormComponent<Datasource>
  implements OnInit {
  types = [];
  selectedType: {};

  constructor(datasourceService: DatasourcesService, injector: Injector) {
    super(
      datasourceService,
      AdministrationModuleConfig,
      DatasourceFeatureConfig,
      injector
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.types = EnumUtility.mapEnumToSelectItems(DatasourceType);
  }

  initializeForm() {
    this.formGroup = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      connectionString: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
    });
  }
}
