import { Component } from '@angular/core';
import { BreadcrumbService } from '@radar-workspace/ui';

@Component({
  selector: 'radar-workspace-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([
      { label: 'Home', routerLink: ['/'] },
      { label: 'About', routerLink: ['/about'] },
    ]);
  }
}
