import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from '@radar-workspace/ui-api';
import { ArrayUtil } from '@radar-workspace/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Perspective } from '../perspectives/perspective.model';
import { PerspectivesService } from '../perspectives/perspectives.service';
import { Field, Section } from './section.model';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class SectionsService implements CrudService<Section> {
  public perspective$: BehaviorSubject<Perspective> = new BehaviorSubject<Perspective>(
    null
  );

  private perspective: Perspective;

  constructor(
    private http: HttpClient,
    private perspectivesService: PerspectivesService
  ) {
    console.log('Constructor Sections Service');
  }

  create(model: Section): Observable<Section> {
    model.id = uuidv4();
    this.perspective.sections.push(model);

    this.perspectivesService.updateLocalStorage$.next();

    return of(model);
  }

  readOne(id: string): Observable<Section> {
    const result = this.perspective.sections.find((model) => model.id == id);

    if (result) {
      console.log('Result ', result);
      return of(result);
    } else {
      throw new Error('Cannot find ID: ' + id);
    }
  }

  readAll(): Observable<Section[]> {
    return of(this.perspective.sections);
  }

  search(searchCriteria: unknown): Observable<Section[]> {
    throw new Error('Method not implemented.');
  }

  update(model: Section): Observable<Section> {
    if (model) {
      const index = this.perspective.sections.findIndex(
        (section) => section.id === model.id
      );

      this.perspective.sections[index] = model;

      this.perspectivesService.updateLocalStorage$.next();
    }

    return of(model);
  }

  delete(model: Section): Observable<Section> {
    if (model) {
      ArrayUtil.removeFromArray(this.perspective.sections, model, 'id');
    }

    return of(model);
  }

  deleteById(id: string): Observable<Section> {
    const model = this.perspective.sections.find((model) => model.id === id);

    if (model) {
      ArrayUtil.removeFromArray(this.perspective.sections, model, 'id');

      this.perspectivesService.updateLocalStorage$.next();
    }

    return of(model);
  }

  addField(sectionId: string, field: Field) {
    const index = this.perspective.sections.findIndex(
      (s) => s.id === sectionId
    );

    field.id = uuidv4();
    this.perspective.sections[index].fields.push(field);
    this.perspectivesService.updateLocalStorage$.next();

    return of(field);
  }

  updateField(sectionId: string, field: Field) {
    const sectionIndex = this.perspective.sections.findIndex(
      (s) => s.id === sectionId
    );

    if (field) {
      const index = this.perspective.sections[sectionIndex].fields.findIndex(
        (f) => f.id === field.id
      );

      this.perspective.sections[sectionIndex].fields[index] = field;
      this.perspectivesService.updateLocalStorage$.next();
    }

    return of(field);
  }

  deleteFieldById(sectionId: string, fieldId: string) {
    const index = this.perspective.sections.findIndex(
      (s) => s.id === sectionId
    );

    const model = this.perspective.sections[index].fields.find(
      (model) => model.id === fieldId
    );

    if (model) {
      ArrayUtil.removeFromArray(
        this.perspective.sections[index].fields,
        model,
        'id'
      );
      this.perspectivesService.updateLocalStorage$.next();
    }

    return of(model);
  }

  readFieldById(sectionId: string, fieldId: string) {
    const sectionIndex = this.perspective.sections.findIndex(
      (s) => s.id === sectionId
    );

    const index = this.perspective.sections[sectionIndex].fields.findIndex(
      (f) => f.id === fieldId
    );

    return of(this.perspective.sections[sectionIndex].fields[index]);
  }

  setPerspectiveById(perspectiveId: string) {
    this.perspectivesService.readOne(perspectiveId).subscribe((pers) => {
      this.setPerspective(pers);
    });
  }

  destroyPerpective() {
    this.setPerspective(null);
  }

  private setPerspective(perspective: Perspective): void {
    this.perspective$.next(perspective);
    this.perspective = perspective;
  }
}
