import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AutoComplete } from 'primeng/autocomplete';
import { forkJoin } from 'rxjs';
import { Perspective } from './administration/perspectives/perspective.model';
import { PerspectivesService } from './administration/perspectives/perspectives.service';
import { SearchResult } from './analytics/search/search.model';
import { SearchService } from './analytics/search/search.service';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
  globalSearchResults = [];
  @ViewChild('globalSearchAutocomplete') globalSearchAutocomplete: AutoComplete;

  constructor(
    public appMain: AppMainComponent,
    public app: AppComponent,
    private searchService: SearchService,
    private perspectiveService: PerspectivesService,
    private router: Router
  ) {}

  doGlobalSearch(event: any) {
    // iterate through all perpectives, find perspective that has global fields, and for each create search request
    this.perspectiveService.readAll().subscribe((perspectives) => {
      const searches = [];
      const perspectiveName = {};

      perspectives.forEach((perspective: Perspective) => {
        perspectiveName[perspective.id] = perspective.name;

        perspective.sections.forEach((section) => {
          section.fields.forEach((field) => {
            if (field.appearsIn) {
              if (field.appearsIn.includes('global')) {
                const searchShema = {
                  perspectiveId: perspective.id,
                  search: {},
                  filter: [],
                };

                searchShema.search[field.key] = event.query;
                searches.push(this.searchService.doSearch(searchShema));
              }
            }
          });
        });
      });

      forkJoin(searches).subscribe((results) => {
        const glc = [];

        results.forEach((result: SearchResult) => {
          glc.push({
            label:
              perspectiveName[result.searchedByShema.perspectiveId] +
              ' (' +
              result.totals +
              ')',
            id: result.searchedByShema.perspectiveId,
            searchShema: result.searchedByShema,
          });
        });

        this.globalSearchResults = glc;
      });
    });
  }

  onGlobalSearchSelected(selected: any) {
    this.globalSearchAutocomplete.writeValue(null);

    this.router.navigate(['/analytics/search'], {
      queryParams: { searchShema: JSON.stringify(selected.searchShema) },
    });
  }

  onLogout() {
    localStorage.setItem('token', JSON.stringify({ loggedIn: false }));
    this.router.navigate(['/login']);
  }
}
