import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  isAuthenticated(): Observable<boolean> {
    try {
      const rawToken = localStorage.getItem('token');
      console.log('ovdje je neki jebeni token ######## ', rawToken);

      const token = JSON.parse(rawToken);

      console.log('ovdje je neki jebeni token ######## ', token);

      return of(token.loggedIn === true);
    } catch (e) {
      return of(false);
    }
  }
}
