<form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <h5>{{ sc.featureConfig.featureName }}</h5>
        <p>{{ sc.featureConfig.featureDescription }}</p>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <label for="perspective">Perspective</label>
            <p-dropdown
              inputId="perspective"
              [options]="perspectives"
              placeholder="Select perspective"
              formControlName="perspective"
            ></p-dropdown>
            <small
              id="perspective-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['perspective'].valid &&
                formGroup.controls['perspective'].dirty
              "
              >Perspective is required.</small
            >
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label for="engine">Engine</label>
            <p-dropdown
              inputId="engine"
              [options]="engines"
              placeholder="Select engine"
              formControlName="engine"
            ></p-dropdown>
            <small
              id="engine-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['engine'].valid &&
                formGroup.controls['engine'].dirty
              "
              >Engine is required.</small
            >
          </div>

          <div class="p-field p-col-12 p-md-3">
            <label for="startFrom">Start from</label>
            <p-calendar formControlName="startFrom"></p-calendar>

            <small
              id="occurance-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['hours'].valid &&
                formGroup.controls['hours'].dirty
              "
              >Start from is required.</small
            >
          </div>

          <div class="p-field p-col-12 p-md-3">
            <label for="occurance">Occurance</label>
            <!-- <p-dropdown
              inputId="occurance"
              [options]="occurances"
              placeholder="Select occurance"
              formControlName="occurance"
            ></p-dropdown> -->

            <p-selectButton
              [options]="occurances"
              formControlName="occurance"
            ></p-selectButton>

            <small
              id="occurance-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['occurance'].valid &&
                formGroup.controls['occurance'].dirty
              "
              >Occurance is required.</small
            >
          </div>
          <div class="p-field p-col-12 p-md-12">
            <label for="hours">Start at</label>
            <p-calendar
              formControlName="hours"
              showTime="true"
              hourFormat="24"
              timeOnly="true"
            ></p-calendar>

            <small
              id="occurance-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['hours'].valid &&
                formGroup.controls['hours'].dirty
              "
              >Hours is required.</small
            >
          </div>
          <div
            *ngIf="formGroup.controls['occurance'].value == 'Weekly'"
            class="p-field p-col-12 p-md-12"
          >
            <label for="hours">Weekly</label>
            <p-selectButton
              [options]="weeklyDays"
              formControlName="weekly"
              multiple="multiple"
            ></p-selectButton>

            <small
              id="occurance-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['weekly'].valid &&
                formGroup.controls['weekly'].dirty
              "
              >Hours is required.</small
            >
          </div>
          <div
            *ngIf="formGroup.controls['occurance'].value == 'Monthly'"
            class="p-field p-col-12 p-md-12"
          >
            <label for="hours">Monthly</label>
            <p-calendar
              formControlName="monthly"
              dateFormat="dd"
              selectionMode="multiple"
            ></p-calendar>

            <small
              id="occurance-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['monthly'].valid &&
                formGroup.controls['monthly'].dirty
              "
              >Hours is required.</small
            >
          </div>
          <div class="p-field p-col-12 p-md-12">
            <label for="summary">Summary</label>
            <textarea
              id="summary"
              type="text"
              rows="4"
              pInputTextarea
              formControlName="summary"
            ></textarea>
            <small
              id="summary-help"
              class="p-error"
              *ngIf="
                !formGroup.controls['summary'].valid &&
                formGroup.controls['summary'].dirty
              "
              >Summary is required.</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid p-jc-between">
    <div class="p-col-2">
      <button
        pButton
        pRipple
        type="submit"
        [disabled]="!formGroup.valid"
        label="Save"
      ></button>
    </div>
    <div class="p-col-1">
      <p-button
        pRipple
        type="button"
        label="Discard"
        (onClick)="onFormDiscard($event)"
        styleClass="p-button-secondary"
      ></p-button>
    </div>
  </div>
</form>
