import { Component, Injector, OnInit } from '@angular/core';
import { AbstractGridComponent } from '@radar-workspace/ui';
import { AnalyticsModuleConfig } from '../../analytics.config';
import { SearchHistoryFeatureConfig } from '../search-history.feature';
import { SearchHistory } from '../search-history.model';
import { SearchHistoryService } from '../search-history.service';

@Component({
  selector: 'radar-workspace-search-history-grid',
  templateUrl: './search-history-grid.component.html',
  styleUrls: ['./search-history-grid.component.scss'],
})
export class SearchHistoryGridComponent
  extends AbstractGridComponent<SearchHistory>
  implements OnInit {
  constructor(searchHistoryService: SearchHistoryService, injector: Injector) {
    super(
      searchHistoryService,
      AnalyticsModuleConfig,
      SearchHistoryFeatureConfig,
      injector
    );
  }
}
