import { Time } from '@angular/common';
import { AbstractModel } from '@radar-workspace/ui';

export interface Job extends AbstractModel {
  perspective: string;
  engine: string;
  occurance: string;
  status: string;
  summary: string;
  hours: Time;
  startFrom: Date;
  monthly: number[];
  weekly: string[];
}

export enum ProccesingEngine {
  LOGSTASH = 'LOGSTASH',
  SPARK = 'SPARK',
}

export enum ProcessingOccurance {
  Once = 'Once',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export enum ProcessingWeekDays {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum ProcessingStatus {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  RUNNING = 'RUNNING',
  INACTIVE = 'INACTIVE',
}
