import { Component, Injector, OnInit } from '@angular/core';
import { AbstractGridComponent } from '@radar-workspace/ui';
import { ObjectUtility } from '@radar-workspace/utils';
import { forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { PerspectivesService } from '../../../administration/perspectives/perspectives.service';
import { ProcessingModuleConfig } from '../../processing.config';
import { JobsFeatureConfig } from '../job.feature';
import { Job } from '../job.model';
import { JobsService } from '../jobs.service';

@Component({
  selector: 'radar-workspace-jobs-grid',
  templateUrl: './jobs-grid.component.html',
  styleUrls: ['./jobs-grid.component.scss'],
})
export class JobsGridComponent
  extends AbstractGridComponent<Job>
  implements OnInit {
  statusToSeverityMap = {
    CREATED: 'info',
    ACTIVE: 'success',
    RUNNING: 'warning',
    INACTIVE: 'danger',
  };

  constructor(
    jobsService: JobsService,
    injector: Injector,
    private perspectiveService: PerspectivesService
  ) {
    super(jobsService, ProcessingModuleConfig, JobsFeatureConfig, injector);
  }

  initializeGridMenu() {
    super.initializeGridMenu();

    console.log('this.gridRecordMenuSelected', this.gridRecordMenuSelected);

    this.gridMenuItems.unshift({
      label: 'Activate/Deactivate',
      icon: 'pi pi-fw pi-lock',
      command: () => this.onActivateDeactivateDialog(),
    });

    this.gridMenuItems.unshift({
      label: 'Start job',
      icon: 'pi pi-fw pi-clock',
      command: () => this.onStartJobManualy(),
    });
  }

  onActivateDeactivateDialog() {
    console.log('onActivateDeactivateDialog...');
  }

  onStartJobManualy() {
    console.log('onStartJobManualy...');
  }

  initializeGrid() {
    this.service
      .readAll()
      .pipe(
        mergeMap((records: any[]) => {
          console.log(
            'Stigeli rezultti u JOB gridu sa početnog servisa: ',
            records
          );
          return forkJoin(
            records.map((record) => {
              return this.perspectiveService.readOne(record.perspective).pipe(
                map((per: any) => {
                  const nr = ObjectUtility.copy(record);
                  nr.perspective = per.name;
                  return nr;
                })
              );
            })
          );
        })
      )
      .subscribe((data) => {
        // Your logic here
        this.gridRecords = data;
      });
  }
}
