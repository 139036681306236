import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AboutComponent } from './about/about/about.component';
import { AppLoginComponent } from './login/app.login.component';
import { AppHelpComponent } from './help/app.help.component';
import { DatasourcesGridComponent } from './administration/datasources/datasources-grid/datasources-grid.component';
import { DatasourcesFormComponent } from './administration/datasources/datasources-form/datasources-form.component';
import { PerspectivesGridComponent } from './administration/perspectives/perspectives-grid/perspective-grid.component';
import { PerspectivesFormComponent } from './administration/perspectives/perspectives-form/perspectives-form.component';
import { HomeComponent } from './home/home.component';
import { JobsGridComponent } from './processing/jobs/jobs-grid/jobs-grid.component';
import { JobsFormComponent } from './processing/jobs/jobs-form/jobs-form.component';
import { HistoryGridComponent } from './processing/history/history-grid/history-grid.component';
import { SearchHistoryGridComponent } from './analytics/search-history/search-history-grid/search-history-grid.component';
import { SearchGridComponent } from './analytics/search/search-grid/search-grid.component';
import { SectionGridComponent } from './administration/sections/section-grid/section-grid.component';
import { MappingsFormComponent } from './administration/perspectives/mappings-form/mappings-form.component';
import { AuthGuard } from './auth/auth.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppMainComponent,
          canActivate: [AuthGuard],
          children: [
            { path: '', component: HomeComponent },
            { path: 'about', component: AboutComponent },
            { path: 'help', component: AppHelpComponent },
            {
              path: 'administration/datasources',
              component: DatasourcesGridComponent,
            },
            {
              path: 'administration/datasources/edit/:id',
              component: DatasourcesFormComponent,
            },
            {
              path: 'administration/datasources/create',
              component: DatasourcesFormComponent,
            },
            {
              path: 'administration/perspectives',
              component: PerspectivesGridComponent,
            },
            {
              path: 'administration/perspectives/create',
              component: PerspectivesFormComponent,
            },
            {
              path: 'administration/perspectives/edit/:id',
              component: PerspectivesFormComponent,
            },
            {
              path: 'administration/perspectives/define-fields/:id',
              component: SectionGridComponent,
            },
            {
              path: 'administration/perspectives/define-mappings/:id',
              component: MappingsFormComponent,
            },
            {
              path: 'processing/jobs',
              component: JobsGridComponent,
            },
            {
              path: 'processing/jobs/edit/:id',
              component: JobsFormComponent,
            },
            {
              path: 'processing/jobs/create',
              component: JobsFormComponent,
            },
            {
              path: 'processing/history',
              component: HistoryGridComponent,
            },
            {
              path: 'analytics/search-history',
              component: SearchHistoryGridComponent,
            },
            {
              path: 'analytics/search',
              component: SearchGridComponent,
            },
          ],
        },
        { path: 'login', component: AppLoginComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      { scrollPositionRestoration: 'enabled' }
    ),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
