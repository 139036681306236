import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';

@Component({
  selector: 'app-menu',
  template: `
    <ul class="layout-menu">
      <li
        app-menuitem
        *ngFor="let item of model; let i = index"
        [item]="item"
        [index]="i"
        [root]="true"
      ></li>
    </ul>
  `,
})
export class AppMenuComponent implements OnInit {
  model: any[];

  constructor(public app: AppComponent) {}

  ngOnInit() {
    this.model = [
      {
        label: 'Analitycs',
        icon: 'pi pi-fw pi-compass',
        routerLink: ['utilities'],
        items: [
          {
            label: 'Search data',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['analytics/search'],
          },
          {
            label: 'Searching history',
            icon: 'pi pi-fw pi-clock',
            routerLink: ['analytics/search-history'],
          },
        ],
      },
      {
        label: 'Processing',
        icon: 'pi pi-fw pi-star',
        routerLink: ['/uikit'],
        items: [
          {
            label: 'Jobs definitions',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/processing/jobs'],
          },
          {
            label: 'Jobs history',
            icon: 'pi pi-fw pi-clock',
            routerLink: ['/processing/history'],
          },
        ],
      },
      {
        label: 'Administration',
        icon: 'pi pi-fw pi-home',
        items: [
          {
            label: 'Perspectives',
            icon: 'pi pi-fw pi-clone',
            routerLink: ['/administration/perspectives'],
          },
          {
            label: 'Datasources',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/administration/datasources'],
          },
        ],
      },
      {
        label: 'Application',
        icon: 'pi pi-fw pi-compass',
        routerLink: ['utilities'],
        items: [
          {
            label: 'About',
            icon: 'pi pi-fw pi-shield',
            routerLink: ['/about'],
          },
          {
            label: 'Help',
            icon: 'pi pi-fw pi-question-circle',
            routerLink: ['/help'],
          },
        ],
      },
    ];
  }
}
