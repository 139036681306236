import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { MomentModule } from 'ngx-moment';
import { SearchHistoryGridComponent } from './search-history/search-history-grid/search-history-grid.component';
import { SearchGridComponent } from './search/search-grid/search-grid.component';
import { ToolbarModule } from 'primeng/toolbar';
import { TabViewModule } from 'primeng/tabview';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SliderModule } from 'primeng/slider';
import { PanelModule } from 'primeng/panel';
import { DividerModule } from 'primeng/divider';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressBarModule } from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { KeyFilterModule } from 'primeng/keyfilter';

@NgModule({
  declarations: [SearchHistoryGridComponent, SearchGridComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    MenuModule,
    TagModule,
    ChipModule,
    MomentModule,
    ToolbarModule,
    TabViewModule,
    ChipsModule,
    CalendarModule,
    InputNumberModule,
    InputSwitchModule,
    SliderModule,
    PanelModule,
    DividerModule,
    SidebarModule,
    ProgressBarModule,
    CheckboxModule,
    KeyFilterModule,
  ],
})
export class AnalyticsModule {}
