import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractFormComponent } from '@radar-workspace/ui';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdministrationModuleConfig } from '../../administration.config';
import { SectionFeatureConfig } from '../section.feature';
import { Section } from '../section.model';
import { SectionsService } from '../sections.service';

@Component({
  selector: 'radar-workspace-section-form',
  templateUrl: './section-form.component.html',
  styleUrls: ['./section-form.component.scss'],
})
export class SectionFormComponent
  extends AbstractFormComponent<Section>
  implements OnInit {
  constructor(
    private sectionsService: SectionsService,
    injector: Injector,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    super(
      sectionsService,
      AdministrationModuleConfig,
      SectionFeatureConfig,
      injector
    );
  }

  ngOnInit() {
    super.ngOnInit();

    console.log('******** this.config.data', this.config.data);

    if (this.config.data && this.config.data.id) {
      this.formMode = 'edit';
    } else {
      this.formMode = 'create';
    }
  }

  initializeForm() {
    this.formGroup = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      fields: new FormControl(''),
    });
  }

  populateForm() {
    let id = null;

    if (this.config.data && this.config.data.id) {
      this.formMode = 'edit';
      id = this.config.data.id;
    } else {
      this.formMode = 'create';
    }

    if (id) {
      this.formMode = 'edit';
      this.service.readOne(id).subscribe((record) => {
        this.formModelData = record;
        this.formGroup.patchValue(record);
      });
    }
  }

  onSectionFormDialogSave(): void {
    console.log('onSectionFormDialogSave');

    const model = this.formGroup.value as Section;

    if (this.formMode == 'create') {
      model.fields = [];
      console.log('--- create', model);
      this.sectionsService.create(model).subscribe((data) => {
        console.log('neki data došao nakon createa', data);
        this.ref.close(data);
      });
    } else if (this.formMode == 'edit') {
      console.log('--- edit', model);
      this.sectionsService.update(model).subscribe((data) => {
        this.ref.close(data);
      });
    }
  }

  onSectionFormDialogDiscard(): void {
    console.log('onSectionFormDialogDiscard');
    this.ref.close(null);
  }
}
