import { AbstractModel } from '@radar-workspace/ui';

export interface Section extends AbstractModel {
  perspectiveId: string;
  name: string;
  description: string;
  fields: Field[];
}
export interface Relation extends AbstractModel {
  name: string;
  perspectiveId: string;
  perspectiveField: string;
  label: string;
}

export interface Field extends AbstractModel {
  name: string;
  key: string;
  type: string;
  description: string;
  appearsIn: string[];
  relations: Relation[];
}

export enum FieldType {
  TEXT = 'Text',
  KEYWORD = 'Keyword',
  TEXT_KEYWORD = 'Text+Keyword',
  DATE = 'Date',
  LONG = 'Long',
  BIGINT = 'Bigint',
  INTEGER = 'Integer',
  SHORT = 'Short',
  BYTE = 'Byte',
  DOUBLE = 'Double',
  FLOAT = 'Float',
  HALF_FLOAT = 'Half Float',
  SCALED_FLOAT = 'Scaled Float',
}
