import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { JobsGridComponent } from './jobs/jobs-grid/jobs-grid.component';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { JobsFormComponent } from './jobs/jobs-form/jobs-form.component';
import { HistoryGridComponent } from './history/history-grid/history-grid.component';
import { MomentModule } from 'ngx-moment';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
  declarations: [JobsGridComponent, JobsFormComponent, HistoryGridComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule,
    MenuModule,
    TagModule,
    ChipModule,
    MomentModule,
    CalendarModule,
    SelectButtonModule,
  ],
})
export class ProcessingModule {}
