import { Component, Injector, OnInit } from '@angular/core';
import { AbstractGridComponent } from '@radar-workspace/ui';
import { AdministrationModuleConfig } from '../../administration.config';
import { PerspectiveFeatureConfig } from '../perspective.feature';
import { Perspective } from '../perspective.model';
import { PerspectivesService } from '../perspectives.service';

@Component({
  selector: 'radar-workspace-perspective-grid',
  templateUrl: './perspective-grid.component.html',
  styleUrls: ['./perspective-grid.component.scss'],
})
export class PerspectivesGridComponent
  extends AbstractGridComponent<Perspective>
  implements OnInit {
  constructor(perspectivesService: PerspectivesService, injector: Injector) {
    super(
      perspectivesService,
      AdministrationModuleConfig,
      PerspectiveFeatureConfig,
      injector
    );
  }

  initializeGridMenu() {
    super.initializeGridMenu();

    this.gridMenuItems.unshift({
      label: 'Define mappings',
      icon: 'pi pi-fw pi-sitemap',
      command: () => this.onGridMenuDefineMappings(),
    });

    this.gridMenuItems.unshift({
      label: 'Define fields',
      icon: 'pi pi-fw pi-key',
      command: () => this.onGridMenuDefineFields(),
    });
  }

  onGridMenuDefineMappings() {
    this.router.navigate(
      [
        '/' +
          this.sc.moduleConfig.moduleUrlPrefix +
          '/' +
          this.sc.featureConfig.featureUrlPrefix +
          '/define-mappings/' +
          this.gridRecordMenuSelected.id,
      ],
      {
        relativeTo: this.route,
      }
    );
  }

  onGridMenuDefineFields() {
    this.router.navigate(
      [
        '/' +
          this.sc.moduleConfig.moduleUrlPrefix +
          '/' +
          this.sc.featureConfig.featureUrlPrefix +
          '/define-fields/' +
          this.gridRecordMenuSelected.id,
      ],
      {
        relativeTo: this.route,
      }
    );
  }
}
