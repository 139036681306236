<div class="exception-body error-page">
    <div class="exception-top">
        <img src="assets/layout/images/icon-error.png" alt="poseidon-layout" />
    </div>

    <div class="exception-bottom">
        <div class="exception-wrapper">
            <span class="exception-summary">Exception Occured</span>
            <span class="exception-detail">Please Contact System Admin</span>
            <button pButton pRipple [routerLink]="['/']" label="Dashboard" icon="pi pi-home"></button>
            <a [routerLink]="['/']" class="logo">
                <img src="assets/layout/images/logo.svg" alt="poseidon-layout" class="logo-icon"  />
            </a>
            <span class="exception-footer">PrimeNG Poseidon</span>
        </div>
    </div>
</div>
