import { Directive, Injector, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CrudService,
  FeatureConfig,
  ModuleConfig,
} from '@radar-workspace/ui-api';
import { SolutionConfiguration } from '@radar-workspace/utils';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';
import { Table } from 'primeng/table';
import { AbstractModel } from '../model/abstract.model';
import { AbstractService } from '../service/abstract.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractGridComponent<T> implements OnInit {
  sc: SolutionConfiguration;
  gridMenuItems: MenuItem[];
  gridRecords: AbstractModel[];
  gridRecordsSelected: AbstractModel[];
  gridRecordMenuSelected: AbstractModel;

  breadcrumbService: BreadcrumbService;
  confirmationService: ConfirmationService;
  messageService: MessageService;

  router: Router;
  route: ActivatedRoute;

  @ViewChild('dt') table: Table;
  @ViewChild('menu') gridRecordMenu: Menu;

  constructor(
    protected service: CrudService<T>,
    protected moduleConfig: Type<ModuleConfig>,
    protected featureConfig: Type<FeatureConfig>,
    protected injector: Injector
  ) {
    this.sc = new SolutionConfiguration(moduleConfig, featureConfig);

    this.breadcrumbService = this.injector.get(BreadcrumbService);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.confirmationService = this.injector.get(ConfirmationService);
    this.messageService = this.injector.get(MessageService);
  }

  ngOnInit(): void {
    this.setBreadcrumbs();
    this.initializeGrid();
    this.initializeGridMenu();
  }

  setBreadcrumbs() {
    this.breadcrumbService.setItems([
      {
        label: this.sc.moduleConfig.moduleName,
        routerLink: ['/' + this.sc.moduleConfig.moduleUrlPrefix],
      },
      {
        label: this.sc.featureConfig.featureName,
        routerLink: [
          '/' +
            this.sc.moduleConfig.moduleUrlPrefix +
            '/' +
            this.sc.featureConfig.featureUrlPrefix,
        ],
      },
    ]);
  }

  initializeGrid() {
    this.service.readAll().subscribe((records) => {
      this.gridRecords = records;
    });
  }

  initializeGridMenu() {
    if (!this.gridMenuItems) {
      this.gridMenuItems = [
        {
          label: 'Edit',
          icon: 'pi pi-fw pi-cog',
          command: () => this.onGridMenuEdit(),
        },
        {
          label: 'Delete',
          icon: 'pi pi-fw pi-trash',
          command: () => this.onGridMenuDelete(),
        },
      ];
    }
  }

  onGridShowRowMenu(event: Event, gridRecord: AbstractModel) {
    this.gridRecordMenuSelected = gridRecord;
    this.gridRecordMenu.toggle(event);
  }

  onGridMenuEdit() {
    console.log(
      'Edit ' + this.sc.featureConfig.featureName.toLocaleLowerCase()
    );

    this.router.navigate(
      [
        '/' +
          this.sc.moduleConfig.moduleUrlPrefix +
          '/' +
          this.sc.featureConfig.featureUrlPrefix +
          '/edit/' +
          this.gridRecordMenuSelected.id,
      ],
      {
        relativeTo: this.route,
      }
    );
  }

  onGridMenuDelete() {
    console.log(
      'Delete ' + this.sc.featureConfig.featureName.toLocaleLowerCase()
    );

    this.confirmationService.confirm({
      message:
        'Are you sure that you want to delete this ' +
        this.sc.featureConfig.featureName.toLocaleLowerCase() +
        '?',
      accept: () => {
        console.log('Yes delete');
        this.service
          .deleteById(this.gridRecordMenuSelected.id)
          .subscribe((d) => {
            console.log('deleted object', d);
            this.messageService.add({
              key: 'bc',
              severity: 'success',
              summary: 'Success',
              detail:
                'Deleted ' +
                this.sc.featureConfig.featureName.toLocaleLowerCase(),
            });
          });
      },
    });
  }

  onGridMenuCreate() {
    this.router.navigate(['create'], { relativeTo: this.route });
  }

  onGridSort() {
    console.log('onGridSort');
  }
}
