import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { TagModule } from 'primeng/tag';
import { DividerModule } from 'primeng/divider';

@NgModule({
  declarations: [AboutComponent],
  imports: [CommonModule, TagModule, DividerModule],
})
export class AboutModule {}
