import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {
  formGroup: FormGroup;
  msgs = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      username: this.formBuilder.control('', Validators.required),
      password: this.formBuilder.control('', Validators.required),
    });
  }

  onFormSubmit() {
    if (
      this.formGroup.value.username == 'john.doe' &&
      this.formGroup.value.password == 'lozinkica'
    ) {
      const rawToken = { loggedIn: true };
      localStorage.setItem('token', JSON.stringify(rawToken));

      this.router.navigate(['/']);
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Login',
        detail: 'Authentication data are incorect. Try Again!',
      });
    }
  }
}
